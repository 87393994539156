import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { deviceType, LStorageController, prefix } from '../../../../js/constants';
import {
    Age,
    ageObj,
    categoryGroupsObj,
    Gender,
    PricesType,
    pricesTypesObj,
    QuestionType,
    sizesClothesObj,
    sizesShoesObj,
    Style,
    styleObj,
} from '../../../../models/models-front';
import ButtonBack from '../../../ui/button-back/ButtonBack';
import { useLocation } from 'react-router-dom';
import css from './ScreenQuestions.module.scss';
import SexToggle from '../../../ui/sex-toggle/SexToggle';
import Utils from '../../../../js/utils';

const ScreenQuestions = ({
    pageTitle,
    pageSubtitle,
    buttonBackTitle = 'Назад',
    buttonNextTitle = 'Показать результаты',
    nextUrlPart = 'preferences/',
    options,
}: {
    pageTitle: JSX.Element | string;
    pageSubtitle?: string;
    buttonBackTitle?: string;
    buttonNextTitle?: string;
    nextUrlPart?: string;
    options: { [key: string]: boolean };
}) => {
    const [selectedCategories, setSelectedCategories] = useState<string[]>(LStorageController.getCategoryGroups());
    const [age, setAge] = useState<Age>(LStorageController.getAge());
    const [clothSizes, setClothSizes] = useState<string[]>(LStorageController.getClothSizes());
    const [shoeSizes, setShoeSizes] = useState<string[]>(LStorageController.getShoesSizes());
    const [priceType, setPriceType] = useState<PricesType>(LStorageController.getPricesType());
    const [style, setStyle] = useState<Style>(LStorageController.getStyle());
    const [gender, setGender] = useState<Gender>(LStorageController.getGender());

    const clothSizesListEl = useRef<HTMLUListElement>(null);

    const match = useRouteMatch();
    const history = useHistory();
    const search = useLocation().search;
    const category = new URLSearchParams(search).get('category');
    const section = match.url.split('/')[1];

    const gotoNextScreen = () => {
        history.push(`/${section}/${nextUrlPart}`);
    };

    useEffect(() => LStorageController.setCategoryGroups(selectedCategories), [selectedCategories]);
    useEffect(() => LStorageController.setAge(age), [age]);
    useEffect(() => LStorageController.setClothSizes(clothSizes), [clothSizes]);
    useEffect(() => LStorageController.setShoesSizes(shoeSizes), [shoeSizes]);
    useEffect(() => LStorageController.setPricesType(priceType), [priceType]);
    useEffect(() => LStorageController.setStyle(style), [style]);
    useEffect(() => LStorageController.setGender(gender), [gender]);

    useEffect(() => {
        if (clothSizesListEl?.current && deviceType === 'MOBILE') {
            clothSizesListEl.current.scrollLeft = (clothSizesListEl.current.scrollWidth - clothSizesListEl.current.offsetWidth) / 2;
        }
    }, []);

    const buttonNext = (
        <button
            className={prefix + '__button-main-black ' + css[prefix + '__btn-next']}
            type="button"
            disabled={
                // todo - sex ?
                (!selectedCategories.length && options[QuestionType.CATEGORIES]) ||
                (!clothSizes.length && options[QuestionType.CLOTHES_SIZES]) ||
                (!shoeSizes.length && options[QuestionType.SHOES_SIZES]) ||
                (!priceType.length && options[QuestionType.PRICES]) ||
                (!style.length && options[QuestionType.STYLE])
            }
            onClick={gotoNextScreen}
        >
            {buttonNextTitle}
        </button>
    );

    return (
        <section className={prefix + '__layout ' + css[prefix + '__layout']}>
            <div className={prefix + '__layout-center ' + css[prefix + '__layout-center']}>
                <div className={css[prefix + '__content']}>
                    <h2 className={prefix + '__page-title-left ' + prefix + '__mobile'}>{pageTitle}</h2>

                    <div className={css[prefix + '__options-block']}>
                        {!options[QuestionType.CATEGORIES] ? null : (
                            <div className={css[prefix + '__field']}>
                                <ul className={css[prefix + '__field-category-list']}>
                                    {categoryGroupsObj.map((cat, index) => {
                                        const selected = selectedCategories.includes(cat);
                                        return (
                                            <li
                                                className={
                                                    css[prefix + '__field-category-item'] +
                                                    (selected ? ' ' + css[prefix + '__field-category-item--selected'] : '') +
                                                    ' ' +
                                                    css[prefix + '__field-category-item--' + index]
                                                }
                                                key={index}
                                                onClick={() => Utils.selectItem(cat, selectedCategories, setSelectedCategories)}
                                            >
                                                {cat}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        )}
                        {!options[QuestionType.SEX] ? null : (
                            <div className={css[prefix + '__field']}>
                                <div className={css[prefix + '__field-label']}>Пол</div>
                                <div className={css[prefix + '__field-sex']}>
                                    <SexToggle
                                        size="big"
                                        selected={gender}
                                        onChange={(value: Gender) => {
                                            setGender(value);
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        {!options[QuestionType.AGE] ? null : (
                            <div className={css[prefix + '__field']}>
                                <div className={css[prefix + '__field-label']}>Возраст</div>
                                <ul className={css[prefix + '__field-size-list'] + ' ' + css[prefix + '__field-size-list--age']}>
                                    {ageObj.map((ageItem, index) => {
                                        const selected = age === ageItem.code;
                                        return (
                                            <li
                                                className={
                                                    css[prefix + '__field-size-item'] +
                                                    ' ' +
                                                    (selected ? ' ' + css[prefix + '__field-size-item--selected'] : '')
                                                }
                                                key={index}
                                                onClick={() => setAge(ageItem.code)}
                                            >
                                                {ageItem.title}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        )}
                        {!options[QuestionType.CLOTHES_SIZES] ? null : (
                            <div className={css[prefix + '__field']}>
                                <div className={css[prefix + '__field-label']}>Размер одежды</div>
                                <ul className={css[prefix + '__field-size-list'] + ' ' + css[prefix + '__field-size-list--clothes']} ref={clothSizesListEl}>
                                    {sizesClothesObj.map((size, index) => {
                                        const selected = clothSizes.includes(size);
                                        return (
                                            <li
                                                className={
                                                    css[prefix + '__field-size-item'] + (selected ? ' ' + css[prefix + '__field-size-item--selected'] : '')
                                                }
                                                key={index}
                                                onClick={() => Utils.selectItem(size, clothSizes, setClothSizes)}
                                            >
                                                {size}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        )}
                        {!options[QuestionType.SHOES_SIZES] ? null : (
                            <div className={css[prefix + '__field']}>
                                <div className={css[prefix + '__field-label']}>Размер обуви</div>
                                <ul className={css[prefix + '__field-size-list'] + ' ' + css[prefix + '__field-size-list--shoes']}>
                                    {sizesShoesObj.map((size, index) => {
                                        const selected = shoeSizes.includes(size);
                                        return (
                                            <li
                                                className={
                                                    css[prefix + '__field-size-item'] + (selected ? ' ' + css[prefix + '__field-size-item--selected'] : '')
                                                }
                                                key={index + '_'}
                                                onClick={() => Utils.selectItem(size, shoeSizes, setShoeSizes)}
                                            >
                                                {size}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        )}
                        {!options[QuestionType.PRICES] ? null : (
                            <div className={css[prefix + '__field']}>
                                <div className={css[prefix + '__field-label']}>Предпочтения по цене</div>
                                <ul className={css[prefix + '__field-size-list']}>
                                    {pricesTypesObj.map((priceItem, index) => {
                                        const selected = priceType === priceItem.code;
                                        return (
                                            <li
                                                className={
                                                    css[prefix + '__field-size-item'] + (selected ? ' ' + css[prefix + '__field-size-item--selected'] : '')
                                                }
                                                key={index + '_'}
                                                onClick={() => setPriceType(priceItem.code)}
                                            >
                                                {priceItem.title}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        )}
                        {!options[QuestionType.STYLE] ? null : (
                            <div className={css[prefix + '__field']}>
                                <div className={css[prefix + '__field-label']}>Стиль</div>
                                <ul className={css[prefix + '__field-size-list']}>
                                    {styleObj.map((styleItem, index) => {
                                        const selected = style === styleItem.code;
                                        return (
                                            <li
                                                className={
                                                    css[prefix + '__field-size-item'] + (selected ? ' ' + css[prefix + '__field-size-item--selected'] : '')
                                                }
                                                key={index + '_'}
                                                onClick={() => setStyle(styleItem.code)}
                                            >
                                                {styleItem.code}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
                <div className={prefix + '__mobile ' + css[prefix + '__controls']}>{buttonNext}</div>
            </div>
            <div className={prefix + '__layout-right ' + prefix + '__layout-right--with-nav ' + css[prefix + '__layout-right']}>
                <div className={prefix + '__layout-title-overlay ' + prefix + '__layout-title-overlay--' + section}></div>
                <header className={css[prefix + '__layout-right-header']}>
                    <ButtonBack titleDesktop={buttonBackTitle} />
                    <h2 className={prefix + '__page-title-left'}>{pageTitle}</h2>
                    <h4 className={prefix + '__subtitle'}>{pageSubtitle}</h4>
                </header>
                <div className={css[prefix + '__controls']}>{buttonNext}</div>
            </div>
        </section>
    );
};

export default ScreenQuestions;
