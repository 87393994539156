import { QuestionType } from '../../../../models/models-front';
import ScreenQuestions from '../../common/questions/ScreenQuestions';

const ScreenRecommendedPrefs = () => {
    const pageTitle = (
        <>
            Что из&nbsp;этого тебя
            <br />
            интересует?
        </>
    );

    return (
        <ScreenQuestions
            pageTitle={pageTitle}
            pageSubtitle="Выберите одну или несколько категорий, в которых вы хотели бы получить рекомендации от киберстилиста."
            buttonNextTitle="Далее"
            nextUrlPart="questions"
            options={{ [QuestionType.CATEGORIES]: true, [QuestionType.PRICES]: true, [QuestionType.STYLE]: true }}
        />
    );
};

export default ScreenRecommendedPrefs;
