import { useEffect, useState } from 'react';
import { LStorageController, prefix } from '../../../js/constants';
import { Gender, PricesType, pricesTypesObj, Style, styleObj } from '../../../models/models-front';
import Dropdown from '../dropdown/Dropdown';
import SexToggle from '../sex-toggle/SexToggle';
import css from './Filters.module.scss';

const Filters = ({ onFilterChanged }: { onFilterChanged: (priceType: PricesType, gender: Gender, style: Style) => void }) => {
    const [selectedPrice, setSelectedPrice] = useState<PricesType>(LStorageController.getPricesType());
    const [gender, setGender] = useState<Gender>(LStorageController.getGender());
    const [style, setStyle] = useState<Style>(LStorageController.getStyle());

    useEffect(() => LStorageController.setPricesType(selectedPrice), [selectedPrice]);
    useEffect(() => LStorageController.setGender(gender), [gender]);
    useEffect(() => LStorageController.setStyle(style), [style]);

    useEffect(() => {
        onFilterChanged(selectedPrice, gender, style);
    }, [selectedPrice, gender, style]);

    return (
        <div className={css[prefix + '__options']}>
            <div className={css[prefix + '__options-prices-sex']}>
                <ul className={css[prefix + '__prices']}>
                    {pricesTypesObj.map((price) => {
                        const selected = selectedPrice.includes(price.code);
                        return (
                            <li
                                key={price.code}
                                className={prefix + '__select-box-item' + ' ' + (selected ? ' ' + prefix + '__select-box-item--selected' : '')}
                                onClick={() => setSelectedPrice(price.code)}
                            >
                                {price.title}
                            </li>
                        );
                    })}
                </ul>
                <div>
                    <SexToggle size="small" selected={gender} onChange={(gender: Gender) => setGender(gender)} />
                </div>
            </div>
            <div className={prefix + '__dropdown-wrapper'}>
                <Dropdown options={styleObj} selectedItemCode={style} onSelect={(style: Style) => setStyle(style)} />
            </div>
        </div>
    );
};

export default Filters;
