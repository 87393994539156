import { useHistory } from 'react-router';
import { prefix } from '../../../js/constants';
import css from './ButtonBack.module.scss';

const ButtonBack = ({
    titleMobile,
    titleDesktop,
    url,
    gap = 'big',
    cssClass,
}: {
    titleMobile?: string;
    titleDesktop?: string;
    url?: string;
    gap?: 'big' | 'small';
    cssClass?: string;
}) => {
    const history = useHistory();

    return (
        <button
            className={css[prefix] + ' ' + css[prefix + '--gap-' + gap] + (cssClass ? ' ' + cssClass : '')}
            type="button"
            onClick={
                url
                    ? () => {
                          history.push(url);
                      }
                    : history.goBack
            }
        >
            {!(titleMobile || titleDesktop) ? null : (
                <>
                    <span className={prefix + '__mobile'}>{titleMobile}</span>
                    <span className={prefix + '__desktop'}>{titleDesktop}</span>
                </>
            )}
        </button>
    );
};

export default ButtonBack;
