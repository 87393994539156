import { NavLink } from 'react-router-dom';
import { prefix } from '../../../../js/constants';
import { ILook } from '../../../../models/models';
import css from './LookCollage.module.scss';

const LookCollage = ({ look }: { look: ILook }) => {
    return (
        <NavLink to={`/popular/${look.look_id}`}>
            <div className={css[prefix + '__look-picture']}>
                {look.products.map((_product) => (
                    <div
                        className={css[prefix + '__item'] + ' ' + css[prefix + '__item--' + _product.category_group]}
                        style={{ backgroundImage: `url(${_product.image})` }}
                    ></div>
                ))}
            </div>
        </NavLink>
    );
};

export default LookCollage;
