import { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';
import GarderoboApi, { AppError } from '../../../../js/api';
import { LStorageController, prefix } from '../../../../js/constants';
import { GlobalContext } from '../../../../js/context';
import Utils from '../../../../js/utils';
import { IProductInfo } from '../../../../models/models';
import { ScreenType, UserAction } from '../../../../models/models-front';
import ButtonBack from '../../../ui/button-back/ButtonBack';
import css from './ScreenRecommendedLikes.module.scss';

const ScreenRecommendedLikes = () => {
    const screenType = Utils.getScreenType();
    //[screenType, setScreenType]
    const [products, setProducts] = useState<Array<IProductInfo>>([]);
    const [markedProducts, setMarkedProducts] = useState<{ [key: number]: UserAction | null }>({});
    const [dislikesVisible, setDislikesVisible] = useState<Array<boolean>>([]);

    /** сколько раз запрашивать продукты */
    const PARTS_COUNT = screenType === ScreenType.DESKTOP ? 3 : 10;
    const [partsCount, setPartsCountDesktop] = useState(1);
    /** по сколько продуктов загружать */
    const partSize = screenType === ScreenType.DESKTOP ? 6 : 2;

    const search = useLocation().search;
    //const category = new URLSearchParams(search).get('category');
    const categories = LStorageController.getCategoryGroups();

    const history = useHistory();
    const match = useRouteMatch();
    const section = match.url.split('/')[1];

    const { setError, setIsPending } = useContext(GlobalContext);

    const markProductMobile = (productId: number, action: UserAction) => {
        markProductDesktop(productId, action);
        if (products.length) {
            const productsNew = [...products];
            const first = productsNew.shift();
            setProducts(productsNew);
        }
        if (document && document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }
    };

    const markProductDesktop = (productId: number, action: UserAction) => {
        GarderoboApi.saveUserAction(productId, action)
            .then(() => {
                const newMarkedProducts = { ...markedProducts };
                newMarkedProducts[productId] = action;
                setMarkedProducts(newMarkedProducts);
            })
            .catch((error: Error) => {
                const newMarkedProducts = { ...markedProducts };
                newMarkedProducts[productId] = null;
                setMarkedProducts(newMarkedProducts);

                if (error instanceof AppError) {
                    setError(error.getErrorText());
                }
            });
    };

    /** в 1й версии не делаем */
    const setDislike = (index: number, isVisible: boolean) => {
        // const dislikesNew = [...dislikesVisible];
        // dislikesNew[index] = isVisible;
        // setDislikesVisible(dislikesNew);
    };

    const loadProducts = () => {
        setIsPending(true);
        GarderoboApi.getRecommendations(
            LStorageController.getGender(),
            LStorageController.getClothSizes(),
            LStorageController.getShoesSizes(),
            LStorageController.getAge(),
            LStorageController.getPricesType(),
            LStorageController.getStyle(),
            LStorageController.getCategoryGroups(),
            partSize
        )
            .then((data) => {
                setProducts(data.products);
                setMarkedProducts({});
            })
            .catch((error: AppError) => {
                setError(error.getErrorText());
            })
            .finally(() => {
                setIsPending(false);
            });
    };

    const onResize = () => {
        if (screenType !== Utils.getScreenType()) {
            setError({ text: 'Верните экран в исходное положение!', noOkButton: true });
        } else {
            setError('');
        }
    };

    useEffect(() => {
        loadProducts();
        window.addEventListener('resize', onResize);

        return function cleanup() {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    useEffect(() => {
        console.log('partsCount', partsCount);
        if (Object.keys(markedProducts).length === partSize) {
            if (partsCount === PARTS_COUNT) {
                history.push('/recommended/results');
            } else {
                setPartsCountDesktop(partsCount + 1);
                loadProducts();
            }
        }
    }, [markedProducts]);

    return (
        <>
            {!dislikesVisible[0] ? null : (
                <>
                    <div className={prefix + '__mobile' + ' ' + css[prefix + '__overlay-dark']}></div>
                    <div className={css[prefix + '__dislikes']}>
                        <button
                            className={prefix + '__button-close' + ' ' + css[prefix + '__button-close']}
                            type="button"
                            onClick={() => setDislike(0, false)}
                        ></button>
                        <div className={css[prefix + '__dislikes-content']}>
                            <h4 className={css[prefix + '__dislikes-title']}>Что именно Вам не понравилось?</h4>
                            <div className={css[prefix + '__dislikes-controls']}>
                                <button className={prefix + '__button-reject' + ' ' + prefix + '__button-reject--price'} type="button">
                                    Цена
                                </button>
                                <button className={prefix + '__button-reject' + ' ' + prefix + '__button-reject--color'} type="button">
                                    Цвет
                                </button>
                                <button className={prefix + '__button-reject' + ' ' + prefix + '__button-reject--style'} type="button">
                                    Стиль
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <section className={prefix + '__layout'}>
                <div className={prefix + '__layout-center' + ' ' + css[prefix + '__layout-center']}>
                    <div className={css[prefix + '__products']}>
                        {products.map((product, index) => (
                            <article className={css[prefix + '__product'] + ' ' + prefix + '__mobile'} key={product.id}>
                                <NavLink to={`/similar/products/${product.id}/`}>
                                    <div
                                        className={css[prefix + '__picture']}
                                        style={{ backgroundImage: `url(${product.image || product.images?.[0]})` }}
                                    ></div>
                                </NavLink>
                                <div className={css[prefix + '__details']}>
                                    <div className={css[prefix + '__brand']}>{product.brand}</div>
                                    <div className={css[prefix + '__price']}>{product.price}&nbsp;₽</div>
                                </div>
                                <div className={css[prefix + '__question'] + ' ' + prefix + '__mobile'}>Вам нравится эта вещь?</div>
                                <div className={css[prefix + '__controls']}>
                                    <button
                                        className={prefix + '__button-no' + ' ' + css[prefix + '__controls-btn']}
                                        type="button"
                                        onClick={() => markProductMobile(product.id, UserAction.DISLIKE)}
                                    >
                                        <span className={prefix + '__mobile'}>Нет</span>
                                    </button>
                                    <button
                                        className={prefix + '__button-yes' + ' ' + css[prefix + '__controls-btn']}
                                        type="button"
                                        onClick={() => markProductMobile(product.id, UserAction.LIKE)}
                                    >
                                        <span className={prefix + '__mobile'}>Да</span>
                                    </button>
                                </div>
                                {/* {!dislikesVisible[index] ? null : (
                                    <div className={prefix + '__desktop' + ' ' + css[prefix + '__dislikes-desktop']}>
                                        <button
                                            className={prefix + '__button-close' + ' ' + css[prefix + '__button-close']}
                                            type="button"
                                            onClick={() => setDislike(index, false)}
                                        ></button>
                                        <h4 className={css[prefix + '__dislikes-title']}>Что Вам не понравилось?</h4>
                                        <button className={prefix + '__button-reject' + ' ' + prefix + '__button-reject--price'} type="button">
                                            Цена
                                        </button>
                                        <button className={prefix + '__button-reject' + ' ' + prefix + '__button-reject--color'} type="button">
                                            Цвет
                                        </button>
                                        <button className={prefix + '__button-reject' + ' ' + prefix + '__button-reject--style'} type="button">
                                            Стиль
                                        </button>
                                    </div>
                                )} */}
                            </article>
                        ))}

                        {products.map((product, index) => (
                            <article className={css[prefix + '__product'] + ' ' + prefix + '__desktop'} key={product.id}>
                                <NavLink to={`/similar/products/${product.id}/`}>
                                    <div
                                        className={css[prefix + '__picture']}
                                        style={{ backgroundImage: `url(${product.image || product.images?.[0]})` }}
                                    ></div>
                                </NavLink>
                                <div className={css[prefix + '__controls']}>
                                    <button
                                        className={
                                            prefix +
                                            '__button-no' +
                                            ' ' +
                                            css[prefix + '__controls-btn'] +
                                            (markedProducts[product.id] === UserAction.DISLIKE ? ' ' + prefix + '__button-no--selected' : '')
                                        }
                                        type="button"
                                        onClick={() => markProductDesktop(product.id, UserAction.DISLIKE)}
                                    ></button>
                                    <button
                                        className={
                                            prefix +
                                            '__button-yes' +
                                            ' ' +
                                            css[prefix + '__controls-btn'] +
                                            (markedProducts[product.id] === UserAction.LIKE ? ' ' + prefix + '__button-yes--selected' : '')
                                        }
                                        type="button"
                                        onClick={() => markProductDesktop(product.id, UserAction.LIKE)}
                                    ></button>
                                </div>
                            </article>
                        ))}
                    </div>
                </div>

                <div className={prefix + '__layout-right ' + ' ' + prefix + '__layout-right--with-nav ' + css[prefix + '__layout-right']}>
                    <div className={prefix + '__layout-title-overlay' + ' ' + prefix + '__layout-title-overlay--' + section}></div>
                    <header className={css[prefix + '__layout-right-header']}>
                        <ButtonBack titleDesktop="Назад" />
                        <h2 className={prefix + '__page-title-left'}>
                            Выбери,
                            <br />
                            что понравилось
                        </h2>
                        <h4 className={prefix + '__subtitle' + ' ' + css[prefix + '__subtitle']}>
                            Теперь выбери вещи, которые тебе нравятся или не нравятся, так киберстилист лучше поймет твой вкус.
                        </h4>
                        <ul>
                            {categories.map((cat, i) => (
                                <li key={i} className={prefix + '__subtitle' + ' ' + css[prefix + '__category']} data-cat={cat}>
                                    {cat}
                                </li>
                            ))}
                        </ul>
                    </header>
                </div>
            </section>
        </>
    );
};

export default ScreenRecommendedLikes;
