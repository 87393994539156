import { useContext, useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { deviceType, LStorageController, prefix } from '../../../js/constants';
import ButtonBack from '../button-back/ButtonBack';
import css from './ProductPage.module.scss';
import { IProductInfo } from '../../../models/models';
import GarderoboApi, { AppError } from '../../../js/api';
import { GlobalContext, IErrorExtended } from '../../../js/context';
import { UserAction } from '../../../models/models-front';
import Utils from '../../../js/utils';
import ProductSlider from '../product-slider/ProductSlider';
import ProductImagesCarousel from '../product-images-carousel/ProductImagesCarousel';
class ProductPageHelper {
    public static thumbHeight = 170;
    public static gap = 20;

    // public drawImagesMobileSlider = () => (
    //     {product?.images && product?.images?.length > 1 ? (
    //         <ProductImagesCarousel images={product?.images} />
    //     ) : (
    //         <img className={css[prefix + '__picture-img']} src={currentPic} />
    //     )}
    // )

    public static slideThumb = (dir: number, sliderPos: number, setSliderPos: React.Dispatch<React.SetStateAction<number>>) => {
        let currPos = sliderPos;
        if (dir > 0) {
            currPos -= this.thumbHeight + this.gap;
        } else {
            currPos += this.thumbHeight + this.gap;
        }
        setSliderPos(currPos);
    };

    public static drawThumbs = (product: IProductInfo, sliderPos: number, setCurrentPic: React.Dispatch<React.SetStateAction<string | undefined>>) => (
        <div className={css[prefix + '__thumbnails']} style={{ transform: `translateY(${sliderPos}px)` }}>
            {product.images?.map((picUrl, index) => (
                <button key={index} className={css[prefix + '__btn-thumbnail']} type="button" onClick={() => setCurrentPic(picUrl)}>
                    <div className={css[prefix + '__btn-thumbnail-pic']} style={{ backgroundImage: `url(${picUrl})` }}></div>
                </button>
            ))}
        </div>
    );

    public static drawThumbsSlider = (
        product: IProductInfo,
        sliderPos: number,
        setSliderPos: React.Dispatch<React.SetStateAction<number>>,
        currentPic: string | undefined,
        setCurrentPic: React.Dispatch<React.SetStateAction<string | undefined>>,
        hasDownBtn: boolean
    ) => {
        return deviceType === 'DESKTOP' ? (
            <div className={css[prefix + '__thumbs-slider']}>
                {sliderPos >= 0 ? null : (
                    <button type="button" className={prefix + '__button-nav-vert'} onClick={() => this.slideThumb(-1, sliderPos, setSliderPos)}></button>
                )}
                {this.drawThumbs(product, sliderPos, setCurrentPic)}
                {!hasDownBtn ? null : (
                    <button
                        type="button"
                        className={prefix + '__button-nav-vert ' + prefix + '__button-nav-vert--down'}
                        onClick={() => this.slideThumb(1, sliderPos, setSliderPos)}
                    ></button>
                )}
            </div>
        ) : product?.images && product?.images?.length > 1 ? (
            <ProductImagesCarousel images={product?.images} currentPic={currentPic} setCurrentPic={setCurrentPic} />
        ) : (
            <img className={css[prefix + '__picture-img']} src={currentPic} />
        );
    };

    public static thumbnails = (
        product: IProductInfo,
        sliderPos: number,
        setSliderPos: React.Dispatch<React.SetStateAction<number>>,
        currentPic: string | undefined,
        setCurrentPic: React.Dispatch<React.SetStateAction<string | undefined>>
    ) => {
        const thumbsCount = product.images?.length || 0;
        const hasDownBtn = thumbsCount && thumbsCount > 3 && sliderPos > -1 * ((thumbsCount - 3) * (this.thumbHeight + this.gap) - this.gap);

        return this.drawThumbsSlider(product, sliderPos, setSliderPos, currentPic, setCurrentPic, Boolean(hasDownBtn));
    };

    public static thumbnailsFullView = (
        product: IProductInfo,
        sliderPos: number,
        setSliderPos: React.Dispatch<React.SetStateAction<number>>,
        currentPic: string | undefined,
        setCurrentPic: React.Dispatch<React.SetStateAction<string | undefined>>
    ) => {
        const sliderHeight = window.innerHeight || document.documentElement.clientHeight;
        const thumbsCount = product.images?.length || 0;
        const hasDownBtn = thumbsCount && thumbsCount * (this.thumbHeight + this.gap) - this.gap + sliderPos > sliderHeight;

        return this.drawThumbsSlider(product, sliderPos, setSliderPos, currentPic, setCurrentPic, Boolean(hasDownBtn));
    };

    public static toggleLike = (
        product: IProductInfo | null | undefined,
        isLiked: boolean | undefined,
        setIsLiked: React.Dispatch<React.SetStateAction<boolean | undefined>>,
        setError: (error: string | IErrorExtended) => void
    ) => {
        if (product?.id) {
            const _isLiked = isLiked ? UserAction.UNLIKE : UserAction.LIKE;
            GarderoboApi.saveUserAction(product?.id, _isLiked)
                .then(() => {
                    Utils.toggleLikeInStorage(product.id);
                    setIsLiked(!isLiked);
                })
                .catch((error: AppError) => {
                    setError(error.getErrorText());
                });
        }
    };

    public static btnBuy = (product: IProductInfo) => (
        <a href={product.url} className={prefix + '__button-main-black' + ' ' + css[prefix + '__button-buy']} target="_blank">
            Купить
        </a>
    );
}

const ProductPage = () => {
    const match = useRouteMatch();
    const section = match.url.split('/')[1];

    const { productId } = useParams<{ productId: string }>();
    const [product, setProduct] = useState<IProductInfo | null>(); // todo - load from back or from prev page ?
    const [similarProducts, setSimilarProducts] = useState<Array<IProductInfo>>(); // for total-look

    const [isRobotOpened, setIsRobotOpened] = useState(false);
    const [currentPic, setCurrentPic] = useState<string>();
    const [isFullView, setIsFullView] = useState(false);

    const [isLiked, setIsLiked] = useState<boolean>();

    const [thumbsSliderPos, setThumbsSliderPos] = useState<number>(0);
    const [thumbsSliderFullViewPos, setThumbsSliderFullViewPos] = useState<number>(0);

    const { setError, setIsPending } = useContext(GlobalContext);

    const picContainer = () => (
        <div className={css[prefix + '__picture-container']}>
            <button
                className={prefix + '__button-like ' + prefix + '__button-like--big' + (isLiked ? ' ' + prefix + '__button-like--liked' : '')}
                type="button"
                onClick={() => ProductPageHelper.toggleLike(product, isLiked, setIsLiked, setError)}
            ></button>
            <button
                className={css[prefix + '__picture'] + ' ' + prefix + '__desktop'}
                type="button"
                style={{ backgroundImage: `url(${currentPic})` }}
                onClick={() => {
                    setIsFullView(true);
                }}
            ></button>
            <button className={css[prefix + '__picture'] + ' ' + prefix + '__mobile'} type="button" onClick={() => setIsFullView(true)}>
                {product?.images && product?.images?.length > 1 ? (
                    <ProductImagesCarousel images={product?.images} currentPic={currentPic} setCurrentPic={setCurrentPic} />
                ) : (
                    <img className={css[prefix + '__picture-img']} src={currentPic} />
                )}
            </button>
        </div>
    );

    const history = useHistory();

    useEffect(() => {
        setIsPending(true);
        GarderoboApi.getProductInfo(+productId)
            .then((product) => {
                if (product) {
                    setProduct(product);
                    setIsLiked(Utils.isProductLiked(product.id));
                    setCurrentPic(product.image || product.images?.[0]);
                }
            })
            .catch((error: AppError) => {
                setError(error.getErrorText());
                setProduct(null);
            })
            .finally(() => setIsPending(false));

        if (section === 'total-look') {
            GarderoboApi.getSimilarProducts(
                +productId,
                LStorageController.getClothSizes(),
                LStorageController.getShoesSizes(),
                LStorageController.getPricesType(),
                LStorageController.getStyle(),
                LStorageController.getGender(),
                30
            )
                .then((data) => {
                    setSimilarProducts(data.products);
                })
                .catch((error: AppError) => {
                    console.error(error);
                    //setError(error.getErrorText());
                })
                .finally(() => setIsPending(false));
        }
    }, []);

    return (
        <div className={css[prefix]}>
            <section
                className={
                    prefix +
                    '__layout ' +
                    css[prefix + '__layout'] +
                    ' ' +
                    css[prefix + '__layout--' + section] +
                    (isFullView ? ' ' + css[prefix + '__layout--overlayed'] : '')
                }
            >
                {product === undefined ? null : product === null ? (
                    <div className={prefix + '__center-box'}>Продукт не найден</div>
                ) : (
                    <>
                        {!isFullView ? null : (
                            <div className={css[prefix + '__overlay']}>
                                <button
                                    className={prefix + '__button-close' + ' ' + css[prefix + '__btn-close']}
                                    type="button"
                                    onClick={() => setIsFullView(false)}
                                ></button>
                                {ProductPageHelper.thumbnailsFullView(product, thumbsSliderFullViewPos, setThumbsSliderFullViewPos, currentPic, setCurrentPic)}
                                <img className={css[prefix + '__overlay-pic']} src={currentPic} alt={product.name} />
                            </div>
                        )}

                        <div
                            className={
                                prefix +
                                '__layout-center ' +
                                prefix +
                                '__layout-center--with-nav ' +
                                css[prefix + '__layout-center'] +
                                ' ' +
                                prefix +
                                '__desktop'
                            }
                        >
                            <ButtonBack titleDesktop="К результатам" cssClass={css[prefix + '__btn-back']} />
                            <div className={css[prefix + '__pictures']}>
                                <div className={css[prefix + '__thumbnails-wrapper']}>
                                    {ProductPageHelper.thumbnails(product, thumbsSliderPos, setThumbsSliderPos, currentPic, setCurrentPic)}
                                </div>
                                {picContainer()}
                            </div>
                        </div>
                        <div className={prefix + '__layout-right' + ' ' + css[prefix + '__layout-right'] + ' ' + prefix + '__desktop'}>
                            <div className={css[prefix + '__layout-right-content']}>
                                <div className={css[prefix + '__titles']}>
                                    <div className={css[prefix + '__brand']}>{product.brand}</div>
                                    <div className={css[prefix + '__name']}>{product.name}</div>
                                </div>
                                <div className={css[prefix + '__buy-controls']}>
                                    <div className={css[prefix + '__price']}>{product.price}&nbsp;₽</div>
                                    {ProductPageHelper.btnBuy(product)}
                                    <div className={css[prefix + '__sizes-title']}>Размеры в наличии в магазине</div>
                                    <ul className={css[prefix + '__sizes-list']}>
                                        {product.sizes?.map((size, index) => (
                                            <li key={index} className={prefix + '__select-box-item'}>
                                                {size}
                                            </li>
                                        ))}
                                    </ul>
                                    <a className={prefix + '__link-gray'} href={product.url} target="_blank">
                                        Подробнее о товаре
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className={prefix + '__mobile'}>
                            <div className={css[prefix + '__content']}>
                                {picContainer()}
                                <div className={css[prefix + '__details']}>
                                    <div className={css[prefix + '__brand']}>{product.brand}</div>
                                    <div className={css[prefix + '__price']}>{product.price}&nbsp;₽</div>
                                </div>
                                {ProductPageHelper.btnBuy(product)}
                            </div>
                        </div>

                        <div
                            className={css[prefix + '__robot-block'] + (isRobotOpened ? ' ' + css[prefix + '__robot-block--opened'] : '')}
                            onMouseOver={() => setIsRobotOpened(true)}
                            onMouseOut={() => setIsRobotOpened(false)}
                        >
                            <h3 className={css[prefix + '__robot-title']}>Робот-стилист знает, с&nbsp;чем это лучше носить</h3>
                            <button
                                className={prefix + '__button-main' + ' ' + css[prefix + '__button-robot']}
                                type="button"
                                onClick={() => history.push(`/total-look/best-combos/${productId}`)}
                            >
                                Попробовать бесплатно
                            </button>
                        </div>

                        {!similarProducts?.length ? null : (
                            <div className={css[prefix + '__robot-products-block']}>
                                <a className={css[prefix + '__robot-products-title']} href="#product-page-products">
                                    Робот нашёл для тебя другие варианты
                                </a>

                                <div id="product-page-products">
                                    <ProductSlider products={similarProducts} css={{}} />
                                </div>
                            </div>
                        )}
                    </>
                )}
            </section>
        </div>
    );
};

export default ProductPage;
