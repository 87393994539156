import { useContext, useEffect, useState } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import GarderoboApi, { AppError } from '../../../../js/api';
import { LStorageController, prefix } from '../../../../js/constants';
import { GlobalContext } from '../../../../js/context';
import Utils from '../../../../js/utils';
import { IProductInfo } from '../../../../models/models';
import { categoryGroupsObj, PricesType, pricesTypesObj } from '../../../../models/models-front';
import ButtonBack from '../../../ui/button-back/ButtonBack';
import Dropdown from '../../../ui/dropdown/Dropdown';
import ProductCard from '../../../ui/product-card/ProductCard';
import css from './ScreenRecommendedResults.module.scss';

const ScreenRecommendedResults = () => {
    const partSize = 8;

    const [productsPartCount, setProductsPartCount] = useState<number>(partSize);
    const [selectedCategories, setSelectedCategories] = useState<string[]>(LStorageController.getCategoryGroups());
    const [price, setPrice] = useState<PricesType>(LStorageController.getPricesType());
    const [products, setProducts] = useState<Array<IProductInfo>>([]);
    const [allProducts, setAllProducts] = useState<Array<IProductInfo>>([]);

    const match = useRouteMatch();

    const { setError, setIsPending } = useContext(GlobalContext);

    const showMore = () => {
        const newPartCount = productsPartCount + partSize;
        setProductsPartCount(newPartCount);
        if (allProducts.length >= newPartCount) {
            setProducts(allProducts.slice(0, newPartCount));
        } else {
            setProducts(allProducts);
        }
    };

    const loadResults = () => {
        setIsPending(true);
        GarderoboApi.getRecommendations(
            LStorageController.getGender(),
            LStorageController.getClothSizes(),
            LStorageController.getShoesSizes(),
            LStorageController.getAge(),
            LStorageController.getPricesType(),
            LStorageController.getStyle(),
            LStorageController.getCategoryGroups(),
            50
        )
            .then((data) => {
                setAllProducts(data.products);
                setProducts(data.products.slice(0, partSize));
            })
            .catch((error: AppError) => {
                setError(error.getErrorText());
            })
            .finally(() => {
                setProductsPartCount(partSize);
                setIsPending(false);
            });
    };

    useEffect(() => {
        loadResults();
    }, []);

    useEffect(() => {
        LStorageController.setCategoryGroups(selectedCategories);
        loadResults();
    }, [selectedCategories]);

    useEffect(() => {
        LStorageController.setPricesType(price);
        loadResults();
    }, [price]);

    return (
        <section className={prefix + '__layout ' + css[prefix]}>
            <div className={prefix + '__layout-center ' + prefix + '__layout-center--with-nav ' + css[prefix + '__layout-center']}>
                <div className={prefix + '__desktop'}>
                    <ButtonBack titleDesktop="К вопросам" gap="small" />
                </div>

                <div className={css[prefix + '__header']}>
                    <h2 className={prefix + '__page-title-left' + ' ' + css[prefix + '__page-title-left']}>
                        Рекомендации киберстилиста <br />
                        только для тебя
                    </h2>
                    <a className={prefix + '__button ' + css[prefix + '__btn-train']} href={`/recommended/likes`}>
                        Продолжить обучение сетки
                    </a>
                </div>

                <div className={css[prefix + '__options']}>
                    <ul className={css[prefix + '__categories']}>
                        {categoryGroupsObj.map((cat, index) => {
                            const selected = selectedCategories.includes(cat);
                            return (
                                <li
                                    key={index}
                                    className={prefix + '__select-box-item' + ' ' + (selected ? ' ' + prefix + '__select-box-item--selected' : '')}
                                    onClick={() => Utils.selectItem(cat, selectedCategories, setSelectedCategories)}
                                >
                                    {cat}
                                </li>
                            );
                        })}
                    </ul>

                    <div className={css[prefix + '__prices-dropdown']}>
                        <Dropdown
                            options={pricesTypesObj}
                            selectedItemCode={price}
                            onSelect={(priceCode: PricesType) => {
                                setPrice(priceCode);
                            }}
                        />
                    </div>
                </div>
                <ul className={css[prefix + '__looks']}>
                    {products.map((product) => (
                        <li key={product.id} className={css[prefix + '__look']}>
                            <ProductCard product={product} urlPath="recommended" hasLike={true} />
                        </li>
                    ))}
                </ul>
                {allProducts.length <= productsPartCount ? null : (
                    <div className={css[prefix + '__controls']}>
                        <button className={prefix + '__button-main-black' + ' ' + css[prefix + '__btn']} type="button" onClick={showMore}>
                            Показать еще ({allProducts.length - productsPartCount})
                        </button>
                        <a className={prefix + '__button ' + css[prefix + '__btn-train']} href={`/recommended/likes`}>
                            Улучшить результат
                        </a>
                    </div>
                )}
            </div>
        </section>
    );
};

export default ScreenRecommendedResults;
