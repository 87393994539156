import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import GarderoboApi, { AppError } from '../../../../js/api';
import { prefix } from '../../../../js/constants';
import { ILook } from '../../../../models/models';
import { Gender, PricesType, Style } from '../../../../models/models-front';
import Filters from '../../../ui/filters/Filters';
import LookCollage from '../look-collage/LookCollage';
import css from './ScreenPopularContent.module.scss';

/** popular looks for HomePage */
const ScreenPopularContent = () => {
    const partSize = 8;

    const [looks, setLooks] = useState<Array<ILook>>([]);

    const onFilter = (priceType: PricesType, gender: Gender, style: Style) => {
        GarderoboApi.getPopularLooks(priceType, style, gender)
            .then((data) => {
                setLooks(data.looks?.slice(0, partSize));
            })
            .catch((error: AppError) => {
                setLooks([]);
            });
    };

    return (
        <>
            <Filters onFilterChanged={onFilter} />

            {looks?.length ? (
                <>
                    <ul className={css[prefix + '__looks']}>
                        {looks.map((look) => (
                            <li key={look.look_id} className={css[prefix + '__look']}>
                                <LookCollage look={look} />
                            </li>
                        ))}
                    </ul>
                    <NavLink className={prefix + '__link-gray'} to="/popular">
                        Смотреть все образы
                    </NavLink>
                </>
            ) : (
                <div className={prefix + '__center-box'}>Луки не найдены</div>
            )}
        </>
    );
};

export default ScreenPopularContent;
