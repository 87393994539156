import { useContext, useState } from 'react';
import GarderoboApi, { AppError } from '../../../js/api';
import { LStorageController, prefix } from '../../../js/constants';
import { GlobalContext } from '../../../js/context';
import { IProductInfo } from '../../../models/models';
import { Gender, PricesType, Style, UserAction } from '../../../models/models-front';
import Filters from '../../ui/filters/Filters';
import ProductCard from '../../ui/product-card/ProductCard';
import css from './ScreenFavorites.module.scss';

const ScreenFavorites = () => {
    const partSize = 8;

    const [productsPartCount, setProductsPartCount] = useState<number>(partSize);
    const [products, setProducts] = useState<Array<IProductInfo>>();
    const [allProducts, setAllProducts] = useState<Array<IProductInfo>>([]);

    const { setError, setIsPending } = useContext(GlobalContext);

    const onUnLike = (productId: number) => {
        GarderoboApi.saveUserAction(productId, UserAction.UNLIKE)
            .then(() => {
                const _products = [...allProducts].filter((product) => product.id !== productId);
                setAllProducts(_products);
                setProducts(_products.slice(0, productsPartCount));
            })
            .catch((error: Error) => {
                if (error instanceof AppError) {
                    setError(error.getErrorText());
                }
            })
            .finally(() => setProductsPartCount(partSize));
    };

    const showMore = () => {
        const newPartCount = productsPartCount + partSize;
        setProductsPartCount(newPartCount);
        if (allProducts.length >= newPartCount) {
            setProducts(allProducts.slice(0, newPartCount));
        } else {
            setProducts(allProducts);
        }
    };

    const onFilter = (priceType: PricesType, gender: Gender, style: Style) => {
        setIsPending(true);
        GarderoboApi.getFavorites(priceType, LStorageController.getCategoryGroups(), LStorageController.getGender(), LStorageController.getStyle(), 0) // todo - use correct filters
            .then((data) => {
                if (data) {
                    setAllProducts(data.products);
                    setProducts(data.products.slice(0, partSize));
                }
            })
            .catch((error: AppError) => {
                setError(error.getErrorText());
                setAllProducts([]);
                setProducts([]);
            })
            .finally(() => {
                setProductsPartCount(partSize);
                setIsPending(false);
            });
    };

    return (
        <section className={prefix + '__layout ' + css[prefix]}>
            <div className={prefix + '__layout-center ' + css[prefix + '__layout-center']}>
                <header className={css[prefix + '__header']}>
                    <h2 className={prefix + '__page-title-left ' + css[prefix + '__page-title-left']}>Нравится</h2>
                    <h4 className={prefix + '__subtitle ' + css[prefix + '__subtitle']}>
                        Здесь хранится то, что вам понравилось.
                        <br />
                        Используйте иконку «сердечко» – так нейросеть узнаёт о вас всё больше и больше.
                    </h4>
                </header>

                <Filters onFilterChanged={onFilter} />

                {!products ? null : !products.length ? (
                    <div className={prefix + '__center-box'}>Здесь пока ничего нет</div>
                ) : (
                    <div className={css[prefix + '__products']}>
                        {products.map((product, index) => (
                            <ProductCard
                                key={`${product.id}_${index}`}
                                product={product}
                                hasLike={true}
                                isProductLiked={true}
                                onLike={() => onUnLike(product.id)}
                                urlPath="total-look"
                            />
                        ))}
                    </div>
                )}

                {allProducts.length <= productsPartCount ? null : (
                    <div className={css[prefix + '__controls']}>
                        <button className={prefix + '__button-main-black' + ' ' + css[prefix + '__btn']} type="button" onClick={showMore}>
                            Показать еще ({allProducts.length - productsPartCount})
                        </button>
                    </div>
                )}
            </div>
        </section>
    );
};

export default ScreenFavorites;
