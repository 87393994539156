import { IProductInfo } from '../models/models';
import { ISimilarProducts } from '../models/models-front';
import GarderoboApi from './api';
import { LStorageController } from './constants';

class Actions {
    /** загрузка похожих товаров в state */
    public static gotoNextProduct(
        productId: number,
        products: Array<IProductInfo>,
        similarProducts: ISimilarProducts,
        setSimilarProducts: React.Dispatch<React.SetStateAction<ISimilarProducts>>,
        setLookItemId: React.Dispatch<React.SetStateAction<number | undefined>>,
        setIsPending: React.Dispatch<React.SetStateAction<boolean>>,
        direction: number
    ) {
        if (!productId) return;

        let initialProductId = productId;
        let product = similarProducts[productId];
        if (!product) {
            for (let _productId of Object.keys(similarProducts)) {
                if (similarProducts[+_productId].products.find((_product) => _product.id === productId)) {
                    initialProductId = +_productId;
                    product = similarProducts[+_productId];
                    break;
                }
            }
        }
        setLookItemId(productId);

        if (product) {
            let index = (product.index + direction) % product.products.length;
            index = index < 0 ? product.products.length + index : index;
            setSimilarProducts({
                ...similarProducts,
                [initialProductId]: {
                    index,
                    products: product.products,
                },
            });
        } else {
            setIsPending(true);
            GarderoboApi.getSimilarProducts(
                productId,
                LStorageController.getClothSizes(),
                LStorageController.getShoesSizes(),
                LStorageController.getPricesType(),
                LStorageController.getStyle(),
                LStorageController.getGender(),
                10
            )
                .then((data) => {
                    const currentProduct = products?.find((_product) => _product.id === productId);
                    setSimilarProducts({
                        ...similarProducts,
                        [productId]: {
                            index: !data.products?.length ? 0 : direction === 1 ? 1 : data.products.length, // берем 1й (либо последний, если идем в другую сторону ), тк в похожих продуктах дублируется текущий - он 0й
                            products: [...(currentProduct ? [currentProduct] : []), ...(data.products || [])],
                        },
                    });
                })
                .finally(() => setIsPending(false));
        }
    }

    /** смена текущего товара на похожий */
    public static updateProductsOnGotoNextProduct(
        products: Array<IProductInfo>,
        lookItemId: number,
        similarProducts: ISimilarProducts,
        setProducts: React.Dispatch<React.SetStateAction<IProductInfo[] | undefined>>
    ) {
        if (products) {
            let product = lookItemId ? similarProducts[lookItemId] : null;

            if (!product) {
                for (let _productId of Object.keys(similarProducts)) {
                    if (similarProducts[+_productId].products.find((_product) => _product.id === lookItemId)) {
                        product = similarProducts[+_productId];
                        break;
                    }
                }
            }

            if (product) {
                const newProduct = product.products[product.index];
                setProducts(
                    products.map((product) => {
                        if (product.id === lookItemId) {
                            return newProduct;
                        } else {
                            return product;
                        }
                    })
                );
            }
        }
    }
}

export default Actions;
