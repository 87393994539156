import { ReactComponent as FacadeSimilarSvg } from '../../../assets/svg/facade-similar.svg';
import classes from './ScreenSimilar.module.scss';
import { ReactComponent as FacadeSimilarDesktopSvg } from '../../../assets/svg/facade-similar-desktop.svg';
import ScreenFileUpload from '../common/upload-file/ScreenFileUpload';
import { useContext } from 'react';
import { SimilarContext } from '../../../js/context';

// todo - create common component for file upload
const ScreenSimilar = () => {
    const pageTitle = (
        <>
            Загрузите фото
            <br />
            и&nbsp;узнайте, где купить
            <br />
            похожие вещи
        </>
    );

    return (
        <ScreenFileUpload
            sectionContext={useContext(SimilarContext)}
            pageTitle={pageTitle}
            css={classes}
            facadeSvg={<FacadeSimilarSvg />}
            facadeDesktopSvg={<FacadeSimilarDesktopSvg />}
        />
    );
};

export default ScreenSimilar;
