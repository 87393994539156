import { prefix } from '../../../js/constants';
import { Gender, genderObj } from '../../../models/models-front';
import css from './SexToggle.module.scss';

const SexToggle = ({ size, selected, onChange }: { size: 'small' | 'big'; selected: Gender; onChange: Function }) => {
    return (
        <div className={css[prefix + '__sex-toggle'] + ' ' + css[prefix + '__sex-toggle--' + size]}>
            {genderObj.map((gender) => (
                <label className={css[prefix + '__sex-toggle-label']} key={gender.code}>
                    <input
                        className={css[prefix + '__sex-toggle-input']}
                        type="radio"
                        name="radio-group"
                        value={gender.code}
                        radioGroup="sex"
                        checked={gender.code === selected}
                        onChange={(e) => onChange(gender.code)}
                    ></input>
                    <div className={css[prefix + '__sex-toggle-btn']}>{gender.title}</div>
                </label>
            ))}
        </div>
    );
};

export default SexToggle;
