import {
    IProductInfo,
    IResponseFavorites,
    IResponseGetNextLook,
    IResponseImageProcessing,
    IResponseLooks,
    IResponsePopularLooks,
    IResponseRecommendations,
    IResponseSession,
    IResponseSimilarProducts,
} from '../models/models';
import { Age, categoryToSubObj, Gender, PricesType, Style, UserAction } from '../models/models-front';
import { LStorage } from './constants';
import { IGlobalContext } from './context';

class GarderoboApi {
    private static baseUrl = 'https://api.garderobo.ai/api/v3/b2c';
    //window.location.hostname.indexOf('localhost') !== -1 ? 'https://testapi.garderobo.ai/api/v3/b2c' : 'https://api.garderobo.ai/api/v3/b2c';

    private static getHeaders() {
        const sessionKey = localStorage.getItem(LStorage._garderoboSessionKey);
        let headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        if (sessionKey) {
            headers.append('Authorization', `Bearer ${sessionKey}`);
        }
        return headers;
    }

    public static startSession(): Promise<IResponseSession> {
        const sessionKey = localStorage.getItem(LStorage._garderoboSessionKey);
        if (sessionKey) {
            return new Promise((resolve) => resolve({ session: sessionKey }));
        }

        return this.fetchSession();
    }

    public static getPopularLooks(prices_type: PricesType, style: Style, gender: Gender): Promise<IResponsePopularLooks> {
        return this.startSession()
            .then(() =>
                fetch(`${this.baseUrl}/get_popular_looks/`, {
                    method: 'post',
                    headers: this.getHeaders(),
                    body: JSON.stringify({ prices_type, style, gender }),
                })
            )
            .catch((error: Error) => {
                throw new AppError(error.stack || error.name, error.message);
            })
            .then((resp) => this.processResponse<IResponsePopularLooks>(resp));
    }

    public static getLook(look_id: number, style: Style, gender: Gender): Promise<IResponseLooks | void> {
        return this.startSession()
            .then(() =>
                fetch(`${this.baseUrl}/get_look/`, {
                    method: 'post',
                    headers: this.getHeaders(),
                    body: JSON.stringify({ look_id, style, gender }),
                })
            )
            .catch((error: Error) => {
                throw new AppError(error.stack || error.name, error.message);
            })
            .then((resp) => this.processResponse<IResponseLooks>(resp));
    }

    public static processImage(
        gender: Gender,
        cloth_sizes: Array<string>,
        shoes_sizes: Array<string>,
        prices_type: PricesType,
        style: Style,
        category_groups?: Array<string>,
        url?: string,
        image?: string
    ): Promise<IResponseImageProcessing> {
        return this.startSession()
            .then(() =>
                fetch(`${this.baseUrl}/image_processing/`, {
                    method: 'post',
                    headers: this.getHeaders(),
                    body: JSON.stringify({
                        gender,
                        cloth_sizes,
                        shoes_sizes,
                        prices_type,
                        style,
                        category_groups: category_groups ? this.getSubcategories(category_groups) : undefined,
                        url,
                        image,
                    }),
                })
            )
            .catch((error: Error) => {
                throw new AppError(error.stack || error.name, error.message);
            })
            .then((resp) => this.processResponse<IResponseImageProcessing>(resp));
    }

    public static getProductInfo(product_id: number): Promise<IProductInfo | null> {
        return this.startSession()
            .then(() =>
                fetch(`${this.baseUrl}/get_product/`, {
                    method: 'post',
                    headers: this.getHeaders(),
                    body: JSON.stringify({ product_id }),
                })
            )
            .catch((error: Error) => {
                throw new AppError(error.stack || error.name, error.message);
            })
            .then((resp) => this.processResponse<IProductInfo>(resp));
    }

    public static getNextLook(
        product_id: number,
        index: number,
        cloth_sizes: Array<string>,
        shoes_sizes: Array<string>,
        prices_type: PricesType,
        style: Style,
        gender: Gender
    ): Promise<IResponseGetNextLook> {
        return this.startSession()
            .then(() =>
                fetch(`${this.baseUrl}/get_next_look/`, {
                    method: 'post',
                    headers: this.getHeaders(),
                    body: JSON.stringify({ product_id, index, cloth_sizes, shoes_sizes, prices_type, style, gender }),
                })
            )
            .catch((error: Error) => {
                throw new AppError(error.stack || error.name, error.message);
            })
            .then((resp) => this.processResponse<IResponseGetNextLook>(resp));
    }

    public static getSimilarProducts(
        product_id: number,
        cloth_sizes: Array<string>,
        shoes_sizes: Array<string>,
        prices_type: PricesType,
        style: Style,
        gender: Gender,
        count: number
    ): Promise<IResponseSimilarProducts> {
        return this.startSession()
            .then(() =>
                fetch(`${this.baseUrl}/get_similar_products/`, {
                    method: 'post',
                    headers: this.getHeaders(),
                    body: JSON.stringify({ product_id, cloth_sizes, shoes_sizes, prices_type, style, gender, count }),
                })
            )
            .catch((error: Error) => {
                throw new AppError(error.stack || error.name, error.message);
            })
            .then((resp) => this.processResponse<IResponseSimilarProducts>(resp));
    }

    public static getRecommendations(
        gender: Gender,
        cloth_sizes: Array<string>,
        shoes_sizes: Array<string>,
        age: Age,
        prices_type: PricesType,
        style: Style,
        category_groups: Array<string>,
        count: number
    ): Promise<IResponseRecommendations> {
        return this.startSession()
            .then(() =>
                fetch(`${this.baseUrl}/get_recommendations/`, {
                    method: 'post',
                    headers: this.getHeaders(),
                    body: JSON.stringify({
                        gender,
                        cloth_sizes,
                        shoes_sizes,
                        age,
                        prices_type,
                        style,
                        category_groups: this.getSubcategories(category_groups),
                        count,
                    }),
                })
            )
            .catch((error: Error) => {
                throw new AppError(error.stack || error.name, error.message);
            })
            .then((resp) => this.processResponse<IResponseRecommendations>(resp));
    }

    public static saveUserAction(product_id: number, action: UserAction): Promise<void> {
        return this.startSession()
            .then(() =>
                fetch(`${this.baseUrl}/save_user_action/`, {
                    method: 'post',
                    headers: this.getHeaders(),
                    body: JSON.stringify({ product_id, action }),
                })
            )
            .catch((error: Error) => {
                throw new AppError(error.stack || error.name, error.message);
            })
            .then((resp) => this.processResponse<void>(resp, false));
    }

    public static getFavorites(
        prices_type: PricesType,
        category_groups: Array<string>, // todo - это надо?
        gender: Gender,
        style: Style,
        page: number
    ): Promise<IResponseFavorites | null> {
        return this.startSession()
            .then(() =>
                fetch(`${this.baseUrl}/get_favourites/`, {
                    method: 'post',
                    headers: this.getHeaders(),
                    body: JSON.stringify({
                        prices_type,
                        gender,
                        style,
                        category_groups: category_groups ? this.getSubcategories(category_groups) : undefined,
                        page,
                    }),
                })
            )
            .catch((error: Error) => {
                throw new AppError(error.stack || error.name, error.message);
            })
            .then((resp) => this.processResponse<IResponseFavorites>(resp));
    }

    private static fetchSession(): Promise<IResponseSession> {
        console.log('fetch session');
        return fetch(`${this.baseUrl}/start_session/`, {
            method: 'post',
            headers: this.getHeaders(),
        })
            .then((resp) => this.processResponse<IResponseSession>(resp))
            .then((data) => {
                localStorage.setItem(LStorage._garderoboSessionKey, data.session);
                return data;
            });
    }

    private static async processResponse<T>(response: Response, isDataExpected = true): Promise<T> {
        let resp: { code: number; result: 'ok' | 'error'; data: T; msg: string } | null;
        try {
            resp = await response.json();
        } catch (error) {
            throw new AppError(response.status, response.statusText || `Сервер вернул некорректный ответ (${error})`);
        }

        if (response.ok) {
            if (resp?.code === 0 && resp?.result === 'ok') {
                if (isDataExpected && !resp.data) {
                    throw new AppError(resp.code, 'Сервер не вернул данные');
                } else {
                    return resp.data;
                }
            } else {
                if (resp?.msg.includes('Invalid image url')) {
                    // todo
                    throw new InvalidImageUrlError();
                }
                throw new AppError(resp?.code || 'unknown', resp?.msg || 'Неизвестная ошибка');
            }
        } else {
            switch (response.status) {
                case 401: // auth error
                    return this.fetchSession().then(() => {
                        document.location.reload();
                        return null as unknown as T;
                    }); // todo - что если тут ошибка бросится?
                default:
                    throw new AppError(response.status, resp?.msg || response.statusText);
            }
        }
    }

    // private static handleResponse<T>(resp: { code: number; result: 'ok' | 'error'; data: T; msg: string }) {
    //     if (resp?.code === 0 && resp?.result === 'ok') {
    //         return resp?.data;
    //     } else {
    //         if (resp?.msg.includes('Invalid image url')) {
    //             throw new InvalidImageUrlError();
    //         }
    //         throw new AppError(resp?.code, resp?.msg);
    //     }
    // }

    private static getSubcategories(category_groups: Array<string>) {
        return Array.prototype.concat.apply(
            [],
            category_groups.map((cat) => categoryToSubObj[cat] || cat)
        );
    }
}

export class AppError extends Error {
    constructor(private code: number | string, msg: string, private context?: IGlobalContext) {
        super();
        this.message = msg;
    }

    public getCode(): number | string {
        return this.code;
    }

    public getErrorText(): string {
        const errorText = `Ошибка: ${this.message} (code: ${this.code})`;
        return errorText;
    }
}

// export class JSError extends Error {}    // todo - remove?
export class InvalidImageUrlError extends Error {}

export default GarderoboApi;
