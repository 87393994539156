import { useContext } from 'react';
import { useHistory } from 'react-router';
import { SimilarContext } from '../../../../js/context';
import ScreenPreferences from '../../common/preferences/ScreenPreferences';

const ScreenSimilarPrefs = () => {
    const history = useHistory();

    return (
        <ScreenPreferences
            onGotoNext={() => {
                history.push(`/similar/results`);
            }}
            sectionContext={useContext(SimilarContext)}
            isCategorySingle={false}
            pageTitle={
                <>
                    Что именно вас
                    <br />
                    интересует на&nbsp;фото?
                </>
            }
            pageSubtitle="Выберите одну или несколько категорий на фото,  в которых необходимо осуществить поиск. Если хотите выбрать все категории — нажмите “выбрать всё”."
        />
    );
};

export default ScreenSimilarPrefs;
