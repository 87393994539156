import { QuestionType } from '../../../../models/models-front';
import ScreenQuestions from '../../common/questions/ScreenQuestions';

const ScreenSimilarQues = () => {
    return (
        <ScreenQuestions
            pageTitle="Пара вопросов"
            pageSubtitle="Искусственный интеллект сможет найти вещи, которые более точно подходят для вас, если ответите на несколько вопросов."
            buttonBackTitle="К выбору категорий"
            options={{
                [QuestionType.SEX]: true,
                [QuestionType.CLOTHES_SIZES]: true,
                [QuestionType.SHOES_SIZES]: true,
                [QuestionType.PRICES]: true,
                [QuestionType.STYLE]: true,
            }}
        />
    );
};

export default ScreenSimilarQues;
