import { IProcessedImageItem } from '../models/models';

class CatBox {
    public readonly itemHeight = 37; // todo - set in prefs screen
    public itemWidth = 0;
    public x1: number;
    public x2: number;
    public y1: number;
    public y2: number;

    constructor(private cat: IProcessedImageItem, private imageSizes: ImageSizes, private parentGroup: CatBoxGroup) {
        this.x1 = (this.imageSizes.width * cat.bbox[0]) / this.imageSizes.actualWidth;
        this.x2 = (this.imageSizes.width * cat.bbox[1]) / this.imageSizes.actualWidth;
        this.y1 = (this.imageSizes.height * cat.bbox[2]) / this.imageSizes.actualHeight;
        this.y2 = (this.imageSizes.height * cat.bbox[3]) / this.imageSizes.actualHeight;
    }

    public isOnTheRight(): boolean {
        //console.log(this.midPosY);
        const boxes = this.parentGroup.cats.filter((cat) => cat.y1 <= this.midPosY && this.midPosY <= cat.y2);
        const minLeftPos = Math.min(...boxes.map((box) => box.x1));
        const maxRightPos = Math.max(...boxes.map((box) => box.x2));
        const averageMidPosX = minLeftPos + (maxRightPos - minLeftPos) / 2;

        return this.midPosX > averageMidPosX;
    }

    public get midPosX(): number {
        return this.x1 + (this.x2 - this.x1) / 2;
    }

    public get midPosY(): number {
        return this.y1 + (this.y2 - this.y1) / 2;
    }

    public get midPosYIncludingHeight(): number {
        return this.y1 + (this.y2 - this.y1) / 2 - this.itemHeight / 2;
    }

    public get boxLeft(): number {
        return this.x1;
    }

    public get boxTop(): number {
        return this.y1;
    }

    public get boxWidth(): number {
        return this.x2 - this.x1;
    }

    public get boxHeight(): number {
        return this.y2 - this.y1;
    }

    public get boxSquare(): number {
        return this.boxWidth * this.boxHeight;
    }

    public get itemLeft(): number {
        return this.isOnTheRight() ? this.boxLeft + this.boxWidth : this.boxLeft - this.itemWidth;
    }

    public get itemTop(): number {
        return this.midPosY - this.itemHeight / 2;
    }

    public get label(): string {
        return this.cat.type;
    }

    public get categoryCode(): string {
        return this.cat.category_label;
    }
}

interface ImageSizes {
    width: number;
    height: number;
    actualWidth: number;
    actualHeight: number;
}

export default class CatBoxGroup {
    private _cats: Array<CatBox>;

    constructor(private categories: Array<IProcessedImageItem>, private imageSizes: ImageSizes) {
        this._cats = categories.map((cat) => new CatBox(cat, imageSizes, this));

        const sorted = [...this._cats].sort((a, b) => a.boxSquare - b.boxSquare);
        sorted.forEach((item) => {
            console.log(item.categoryCode, item.isOnTheRight());
        });
    }

    public get cats(): Array<CatBox> {
        return this._cats;
    }

    public calcOvers(): void {
        for (let i = 0; i < this._cats.length; i++) {
            for (let j = i + 1; j < this._cats.length; j++) {
                const a = this._cats[i];
                const b = this._cats[j];
                console.log('__compare__', i, j, a.categoryCode, b.categoryCode);
                if (a.itemLeft + a.itemWidth < b.itemLeft) continue;
                if (a.itemTop + a.itemHeight < b.itemTop) continue;

                if (b.itemLeft + b.itemWidth < a.itemLeft) continue;
                if (b.itemTop + b.itemHeight < a.itemTop) continue;
                console.log('over!', a.itemLeft + a.itemWidth, b.itemLeft, a.itemTop + a.itemHeight, b.itemTop);
            }
        }
    }
}
