import { NavLink } from 'react-router-dom';
import { ReactComponent as FacadePopularSvg } from '../../../assets/svg/facade-popular.svg';
import { ReactComponent as FacadeRecommendedSvg } from '../../../assets/svg/facade-recommended.svg';
import { ReactComponent as FacadeSimilarSvg } from '../../../assets/svg/facade-similar.svg';
import { ReactComponent as FacadeTotalLookSvg } from '../../../assets/svg/facade-total-look.svg';
import { LinkEnum, MAIN_MENU } from '../../../routes';
import ScreenPopularContent from '../popular/popular-content/ScreenPopularContent';
import css from './ScreenHome.module.scss';

const ScreenHome = (props: any) => {
    const prefix = 'garderobo';
    const facadeSvg = (link: LinkEnum) => {
        switch (link) {
            case LinkEnum.SIMILAR:
                return <FacadeSimilarSvg />;
            case LinkEnum.TOTAL_LOOK:
                return <FacadeTotalLookSvg />;
            case LinkEnum.RECOMMENDED:
                return <FacadeRecommendedSvg />;
            case LinkEnum.POPULAR:
                return <FacadePopularSvg />;
            default:
                return <h1>No project match</h1>;
        }
    };

    return (
        <section className={css[prefix]}>
            <header className={css[prefix + '__header']}>
                <h1 className={css[prefix + '__title']}>
                    Твой персональный
                    <br />
                    киберстилист
                </h1>
                <h4 className={prefix + '__subtitle ' + css[prefix + '__subtitle']}>
                    Искусственный интеллект
                    <br />
                    бесплатно подберет вещи
                    <br />
                    и&nbsp;создаст образ именно для тебя
                </h4>
            </header>
            <nav className={css[prefix + '__nav']}>
                <ul className={css[prefix + '__menu-list']}>
                    {MAIN_MENU.map(({ link, title }, index) => (
                        <li className={css[prefix + '__menu-item']} key={index}>
                            <NavLink className={css[prefix + '__menu-item-link']} to={link} exact>
                                {title}
                                <div className={css[prefix + '__facade-svg-wrapper']}>{facadeSvg(link)}</div>
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </nav>
            <hr className={prefix + '__line ' + css[prefix + '__line']} />
            <section className={css[prefix + '__trends']}>
                <a className={css[prefix + '__trends-title']} href="#home-popular-content">
                    Нейросеть знает, что сейчас в тренде
                </a>
                <div id="home-popular-content">
                    <ScreenPopularContent />
                </div>
            </section>
        </section>
    );
};

export default ScreenHome;
