import { prefix } from '../../../js/constants';
import css from './Preloader.module.scss';

const Preloader = ({ type = 'local', isPending }: { type?: 'local' | 'global'; isPending: boolean }) => {
    return (
        <div
            className={
                css[prefix + '__pending-overlay'] +
                ' ' +
                css[prefix + '__pending-overlay--' + type] +
                (isPending ? ' ' + css[prefix + '__pending-overlay--opened'] : '')
            }
        >
            <div className={css[prefix + '__lds-ring']}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default Preloader;
