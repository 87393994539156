import React, { useEffect, useState } from 'react';
import { prefix } from '../../../js/constants';
import css from './Dropdown.module.scss';

const Dropdown = ({
    options,
    selectedItemCode,
    onSelect,
}: {
    options: Array<{ code: string; title: string }>;
    selectedItemCode?: string;
    onSelect?: Function;
}) => {
    const [selected, setSelected] = useState(selectedItemCode || options[0].code);
    const [opened, setOpened] = useState(false);

    const onSelectItem = (code: string) => {
        setSelected(code);
        setOpened(false);
        if (onSelect) {
            onSelect(code);
        }
    };

    const showList = (e: React.MouseEvent) => {
        e.stopPropagation();
        setOpened(!opened);
    };

    const closeDropdown = () => {
        setOpened(false);
    };

    useEffect(() => {
        document.body.addEventListener('click', closeDropdown);

        return function cleanup() {
            window.removeEventListener('click', closeDropdown);
        };
    }, []);

    return (
        <div className={css[prefix] + (opened ? ' ' + css[prefix + '--opened'] : '')}>
            <div className={css[prefix + '__value']} onClick={(e) => showList(e)}>
                {options.find((el) => el.code === selected)?.title}
            </div>
            <ul className={css[prefix + '__list'] + (opened ? ' ' + css[prefix + '__list--opened'] : '')}>
                {options.map(({ code, title }) => (
                    <li
                        key={code}
                        data-value={title}
                        className={css[prefix + '__list-item'] + (selected === code ? ' ' + css[prefix + '__list-item--selected'] : '')}
                        onClick={() => onSelectItem(code)}
                    >
                        {title}
                    </li>
                ))}
            </ul>
        </div>
        // <select className={css[prefix]}>
        //     {options.map(({ value, label }) => (
        //         <option value={value}>
        //             {label}
        //             <div>test</div>
        //         </option>
        //     ))}
        // </select>
    );
};

export default Dropdown;
