import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import GarderoboApi, { AppError, InvalidImageUrlError } from '../../../../js/api';
import CatBoxGroup from '../../../../js/category-box';
import { LStorageController, prefix } from '../../../../js/constants';
import { GlobalContext, IImageUploadContext } from '../../../../js/context';
import Utils from '../../../../js/utils';
import { IProcessedImageItem, IResponseImageProcessing } from '../../../../models/models';
import ButtonBack from '../../../ui/button-back/ButtonBack';
import css from './ScreenPreferences.module.scss';

const ScreenPreferences = ({
    pageTitle,
    pageSubtitle,
    onGotoNext,
    sectionContext,
    isCategorySingle,
}: {
    pageTitle: JSX.Element;
    pageSubtitle: string;
    onGotoNext: (resultData: IResponseImageProcessing | null | undefined, clothCategories: Array<string>) => void;
    sectionContext: IImageUploadContext;
    isCategorySingle: boolean;
}) => {
    const [pic, setPic] = useState<string | null>(null);

    const [categories, setCategories] = useState<Array<IProcessedImageItem>>([]);
    const [selectedGroupItems, setSelectedGroupItems] = useState<Array<IProcessedImageItem>>([]);

    const [catBoxGroup, setCatBoxGroup] = useState<CatBoxGroup>();

    const match = useRouteMatch();
    const history = useHistory();
    const section = match.url.split('/')[1];

    const { url, image, resultData, setResultData, setError: setSimilarError, setClothCategories, setClothCategoriesRus } = sectionContext;

    const { setError, setIsPending } = useContext(GlobalContext);

    const gotoNextScreen = () => {
        // запоминаем выбранные категории одежды
        const clothCategories = selectedGroupItems.map((item) => item.category_label);
        setClothCategories(clothCategories);
        setClothCategoriesRus(selectedGroupItems.map((item) => item.type));

        onGotoNext(resultData, clothCategories);
    };

    const selectAllCategories = () => {
        if (resultData?.items) {
            setSelectedGroupItems(resultData.items);
        }
    };

    const onPicLoad = (e: SyntheticEvent<HTMLImageElement, Event>) => {
        if (categories) {
            const catsGroup = new CatBoxGroup(categories, {
                width: e.currentTarget.width,
                height: e.currentTarget.height,
                actualWidth: e.currentTarget.naturalWidth,
                actualHeight: e.currentTarget.naturalHeight,
            });

            for (let i = 1; i < catsGroup.cats.length; i++) {
                const catBoxPrev = catsGroup.cats[i - 1];
                const catBox = catsGroup.cats[i];
                if (catBox.midPosYIncludingHeight < catBoxPrev.midPosYIncludingHeight) {
                }
            }
            setCatBoxGroup(catsGroup);
        }
    };

    const onSelectGroup = (group: IProcessedImageItem) => {
        if (isCategorySingle) {
            setSelectedGroupItems([group]);
        } else {
            Utils.selectItem(group, selectedGroupItems, setSelectedGroupItems);
        }
    };

    const getResultData = () => {
        if (resultData) {
            return new Promise<IResponseImageProcessing>((resolve) => resolve(resultData));
        } else {
            return GarderoboApi.processImage(
                LStorageController.getGender(),
                LStorageController.getClothSizes(),
                LStorageController.getShoesSizes(),
                LStorageController.getPricesType(),
                LStorageController.getStyle(),
                undefined,
                url || undefined,
                image || undefined
            );
        }
    };

    useEffect(() => {
        if (url || image) {
            setIsPending(true);
            getResultData()
                .then((data) => {
                    setPic(data.url_prepared);
                    const _categories = data.items?.filter((item) => item.products?.length); //отсеиваем категорию, для которой продукты не найдены
                    _categories.sort((a, b) => a.bbox[2] - b.bbox[2]);
                    setCategories(_categories);

                    setResultData(data);
                    if (!data.items?.length || !data.items.find((item) => item.products?.length)) {
                        history.push(`/${section}`);
                        setError('Варианты не найдены');
                    }
                })
                .catch((error: Error) => {
                    setError({ text: 'Что-то пошло не так...', okBtnText: 'Попробовать снова' });
                    // todo - надо ли более детально выводить ошику?
                    //
                    // if (error instanceof InvalidImageUrlError) {
                    //     setSimilarError(error);
                    // } else if (error instanceof AppError) {
                    //     setError(error.getErrorText());
                    // } else {
                    //     console.error(error);
                    // }
                    history.push(`/${section}`);
                })
                .finally(() => setIsPending(false));
        }
    }, []);

    const buttonNext = (
        <button
            className={prefix + '__button-main-black ' + css[prefix + '__button-next']}
            type="button"
            disabled={!selectedGroupItems?.length}
            onClick={gotoNextScreen}
        >
            Далее
        </button>
    );

    const buttonSelectAll = (
        <button
            className={prefix + '__button ' + css[prefix + '__button-select-all']}
            type="button"
            disabled={!resultData?.items}
            onClick={selectAllCategories}
        >
            Выбрать все
        </button>
    );

    return (
        <section className={prefix + '__layout'}>
            <div className={prefix + '__layout-title-overlay ' + prefix + '__layout-title-overlay--' + section}></div>
            <div className={prefix + '__layout-center ' + css[prefix + '__layout-center']}>
                <div className={css[prefix + '__content']}>
                    {pic || pic !== null ? <h2 className={prefix + '__page-title-left ' + prefix + '__mobile'}>{pageTitle}</h2> : null}
                    <div className={css[prefix + '__picture-wrapper']}>
                        {pic === null ? null : !pic ? (
                            <div className={prefix + '__center-box'}>Фото отсутствует</div>
                        ) : (
                            <>
                                <img className={css[prefix + '__picture']} src={pic} alt="Загруженное фото" onLoad={onPicLoad} />
                                {categories.map((cat, index) => {
                                    const selected = selectedGroupItems.includes(cat);
                                    const box = catBoxGroup?.cats[index];

                                    return (
                                        <div
                                            key={index}
                                            className={css[prefix + '__cloth-category-box']}
                                            style={{
                                                left: box?.boxLeft,
                                                width: box?.boxWidth,
                                                top: box?.boxTop,
                                                height: box?.boxHeight,
                                            }}
                                        >
                                            <div
                                                className={
                                                    css[prefix + '__cloth-category-item'] +
                                                    ' ' +
                                                    css[prefix + '__cloth-category-item'] +
                                                    index +
                                                    (selected ? ' ' + css[prefix + '__cloth-category-item--selected'] : '') +
                                                    ' ' +
                                                    css[prefix + '__cloth-category-item--' + (box?.isOnTheRight() ? 'right' : 'left')]
                                                }
                                                onDoubleClick={() => console.log('____change')}
                                                onClick={() => onSelectGroup(cat)}
                                            >
                                                {/*getClothCategory(cat.category_label)*/ cat.type}
                                                {(() => {
                                                    const item = document.querySelector('.' + css[prefix + '__cloth-category-item'] + index);
                                                    if (box) {
                                                        box.itemWidth = (item as any)?.['offsetWidth'] || 0;
                                                    }
                                                    return null;
                                                })()}
                                            </div>
                                        </div>
                                    );
                                })}
                                {catBoxGroup?.calcOvers?.()}
                            </>
                        )}
                    </div>
                </div>

                <div className={css[prefix + '__controls'] + ' ' + prefix + '__mobile'}>
                    {buttonNext}
                    {isCategorySingle ? null : buttonSelectAll}
                </div>
            </div>

            <div className={prefix + '__layout-right ' + prefix + '__layout-right--with-nav ' + css[prefix + '__layout-right']}>
                <header>
                    <ButtonBack titleDesktop="К загрузке фото" />
                    <h2 className={prefix + '__page-title-left'}>{pageTitle}</h2>
                    <h4 className={prefix + '__subtitle'}>{pageSubtitle}</h4>
                </header>
                <div className={css[prefix + '__controls']}>
                    {isCategorySingle ? null : buttonSelectAll}
                    {buttonNext}
                </div>
            </div>
        </section>
    );
};

export default ScreenPreferences;
