import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { prefix } from '../../../js/constants';
import css from './ProductCard.module.scss';
import { IProductInfo } from '../../../models/models';
import GarderoboApi, { AppError } from '../../../js/api';
import { UserAction } from '../../../models/models-front';
import { GlobalContext } from '../../../js/context';
import Utils from '../../../js/utils';
import Preloader from '../preloader/Preloader';

const ProductCard = ({
    product,
    hasLike = false,
    hasRefresh = false,
    customCss,
    urlPath, //todo???
    onLike,
    isProductLiked = false,
    onRefresh,
}: {
    product: IProductInfo;
    hasLike?: boolean;
    hasRefresh?: boolean;
    customCss?: {
        readonly [key: string]: string;
    };
    urlPath: string;
    onLike?: Function;
    isProductLiked?: boolean;
    onRefresh?: (product: IProductInfo, setIsPending: React.Dispatch<React.SetStateAction<boolean>>, direction: number) => void;
}) => {
    const { setError, likedProductId, setLikedProductId } = useContext(GlobalContext);

    const [isLiked, setIsLiked] = useState(isProductLiked || likedProductId?.productId === product.id || Utils.isProductLiked(product.id));
    const [isPending, setIsPending] = useState(false);

    const doRefreshPrev = () => {
        if (onRefresh) {
            onRefresh(product, setIsPending, -1);
        }
    };

    const doRefreshNext = () => {
        if (onRefresh) {
            onRefresh(product, setIsPending, 1);
        }
    };

    const toggleLike = (event: React.MouseEvent) => {
        event.preventDefault();
        const productId = product.id;
        if (onLike) {
            onLike(product.id, !isLiked);
        } else {
            GarderoboApi.saveUserAction(productId, isLiked ? UserAction.UNLIKE : UserAction.LIKE)
                .then(() => {
                    Utils.toggleLikeInStorage(productId);
                    setIsLiked(!isLiked);
                    setLikedProductId({ productId, isLiked: !isLiked });
                })
                .catch((error: Error) => {
                    if (error instanceof AppError) {
                        setError(error.getErrorText());
                    }
                });
        }
    };

    useEffect(() => {
        if (likedProductId?.productId === product.id) {
            setIsLiked(likedProductId.isLiked);
        }
    }, [likedProductId]);

    return (
        <article className={css[prefix] + ' ' + customCss?.[prefix + '__card']}>
            <div className={css[prefix + '__picture-container'] + ' ' + customCss?.[prefix + '__picture-container']}>
                {hasRefresh ? <button className={prefix + '__button-prev'} onClick={doRefreshPrev}></button> : null}
                {hasRefresh ? <button className={prefix + '__button-next'} onClick={doRefreshNext}></button> : null}
                {hasLike ? (
                    <button className={prefix + '__button-like' + (isLiked ? ' ' + prefix + '__button-like--liked' : '')} onClick={toggleLike}></button>
                ) : null}
                <NavLink to={`/${urlPath}/products/${product.id}`} className={prefix + '__link ' + customCss?.[prefix + '__link']}>
                    <div
                        className={css[prefix + '__picture'] + ' ' + customCss?.[prefix + '__picture']}
                        style={{ backgroundImage: `url(${product?.image || product?.images?.[0]})` }}
                    ></div>
                </NavLink>
                <Preloader isPending={isPending} />
            </div>
            <div className={css[prefix + '__details']}>
                <div className={css[prefix + '__details-info']}>
                    <div className={css[prefix + '__brand']}>{product.brand}</div>
                    <div className={css[prefix + '__price']}>{product.price}&nbsp;₽</div>
                </div>
                <a className={prefix + '__button-small'} href={product.url} target="_blank">
                    Купить
                </a>
            </div>
        </article>
    );
};

export default ProductCard;
