import { IProductInfo } from './models';

export enum QuestionType {
    SEX = 'SEX',
    AGE = 'AGE',
    CLOTHES_SIZES = 'CLOTHES_SIZES',
    SHOES_SIZES = 'SHOES_SIZES',
    PRICES = 'PRICES',
    STYLE = 'STYLE',
    CATEGORIES = 'CATEGORIES',
}

export enum ScreenType {
    MOBILE = 'MOBILE',
    DESKTOP = 'DESKTOP',
}

export enum PricesType {
    ECONOM = 'econom',
    NORMAL = 'normal',
    PREMIUM = 'premium',
}

export const pricesTypesObj = [
    { code: PricesType.ECONOM, title: 'Эконом' },
    { code: PricesType.NORMAL, title: 'Средние' },
    { code: PricesType.PREMIUM, title: 'Премиум' },
];

export enum Style {
    CASUAL = 'casual',
    SPORT = 'sport',
}

export const styleObj = [
    { code: Style.CASUAL, title: 'Smart casual' },
    { code: Style.SPORT, title: 'Sport fashion' },
];

export enum Gender {
    MALE = 'male',
    FEMALE = 'female',
}

export const genderObj = [
    { code: Gender.FEMALE, title: 'Женщина' },
    { code: Gender.MALE, title: 'Мужчина' },
];

export enum UserAction {
    LIKE = 'like',
    DISLIKE = 'dislike',
    UNLIKE = 'unlike',
}

export enum Age {
    NONE,
    _18,
    _24,
    _35,
    _50,
    _65,
    MAX,
}

export const ageObj = [
    { code: Age._18, title: '<18' },
    { code: Age._24, title: '19-24' },
    { code: Age._35, title: '25-35' },
    { code: Age._50, title: '36-50' },
    { code: Age._65, title: '51-65' },
    { code: Age.MAX, title: '>65' },
];

export const categoryGroupsObj = ['Верх', 'Верхняя одежда', 'Низ', 'Платья', 'Обувь', 'Аксессуары'];
export const categoryToSubObj: { [key: string]: Array<string> } = {
    Верх: ['Пиджак', 'Комбинезон', 'Рубашка', 'Свитер/кардиган', 'Футболка/поло'],
    Низ: ['Шорты', 'Юбка', 'Брюки'],
    Обувь: ['Обувь'],
    'Верхняя одежда': ['Верхняя одежда'],
    Платья: ['Платье'],
    Аксессуары: ['Сумка', 'Очки', 'Головной убор', 'Варежки/перчатки'],
};
// [
//     'Брюки',
//     'Верхняя одежда',
//     'Головной убор',
//     'Комбинезон',
//     'Обувь',
//     'Очки',
//     'Пиджак',
//     'Платье',
//     'Рубашка',
//     'Свитер/кардиган',
//     'Сумка',
//     'Футболка/поло',
//     'Шорты',
//     'Юбка',
// ];

export const sizesClothesObj = ['4XS', '3XS', '2XS', 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL'];
export const sizesShoesObj = ['34', '35', '36', '37', '38', '39', '40'];

// export const getClothCategory = (categoryCode: string) => {
//     switch (categoryCode) {
//         case 'pants':
//             return 'Брюки';
//         case 'shirt_blouse':
//             return 'Рубашка/Блузка';
//         case 'shoe':
//             return 'Обувь';
//         case 'bag_wallet':
//             return 'Сумка/Кошелек';
//         case 'top_t-shirt_sweatshirt':
//             return 'Топ';
//         case 'jacket':
//             return 'Пиджак';
//         default:
//             return categoryCode;
//     }
// };

export interface ISimilarProducts {
    [key: number]: { index: number; products: Array<IProductInfo> };
}
