import React from 'react';
import { useState } from 'react';
import { prefix } from '../../../js/constants';
import css from './ButtonShare.module.scss';

const ButtonShare = ({ url, title }: { url: string; title: string }) => {
    const [isOpened, setIsOpened] = useState(false);

    console.log('url', url);

    return (
        <div className={css[prefix]}>
            <button
                className={css[prefix + '__button'] + (isOpened ? ' ' + css[prefix + '__button--opened'] : '')}
                type="button"
                onClick={() => {
                    setIsOpened(!isOpened);
                }}
            ></button>
            {!isOpened ? null : (
                <ul className={css[prefix + '__btns-list']}>
                    <li className={css[prefix + '__btns-list-item'] + ' ' + css[prefix + '__btns-list-item--whatsapp']}>
                        <a className={css[prefix + '__btns-list-link']} href={`whatsapp://send?text=${url}`} data-action="share/whatsapp/share" target="_blank">
                            Whatsapp
                        </a>
                    </li>
                    <li className={css[prefix + '__btns-list-item']}>
                        <a className={css[prefix + '__btns-list-link']} href={`https://t.me/share/url?url=${url}&text=${title}`} target="_blank">
                            Telegram
                        </a>
                    </li>
                    <li className={css[prefix + '__btns-list-item']}>
                        <a className={css[prefix + '__btns-list-link']} href={`https://vk.com/share.php?url=${url}`} target="_blank">
                            ВКонтакте
                        </a>
                    </li>
                    <li className={css[prefix + '__btns-list-item']}>
                        {/* 'fb-share-button ' */}
                        <div className={css[prefix + '__btns-list-link']} data-href={url} data-layout="button" data-size="large">
                            <a target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${url}&src=sdkpreparse`} className="fb-xfbml-parse-ignore">
                                Facebook
                            </a>
                        </div>
                    </li>
                </ul>
            )}
        </div>
    );
};

export default React.memo(ButtonShare);
