import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { InvalidImageUrlError } from '../../../../js/api';
import { prefix } from '../../../../js/constants';
import { IImageUploadContext } from '../../../../js/context';
import Utils from '../../../../js/utils';
import ErrorPresenter from '../../../ui/error-presenter/ErrorPresenter';
import _css from './ScreenFileUpload.module.scss';

const onFileOrUrlLoaded = () => {
    const findBtn = document.querySelector(`.${_css[prefix + '__button-find']}`);
    if (findBtn) {
        if (!Utils.isElementInViewport(findBtn)) {
            findBtn.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
    }
};

const ScreenFileUpload = ({
    pageTitle,
    facadeSvg,
    facadeDesktopSvg,
    css,
    sectionContext,
}: {
    pageTitle: JSX.Element;
    facadeSvg: ReactElement;
    facadeDesktopSvg: ReactElement;
    css: {
        readonly [key: string]: string;
    };
    sectionContext: IImageUploadContext;
}) => {
    const [linkValue, setLinkValue] = useState<string>('');
    const [fileValue, setFileValue] = useState<File | null>(null);
    const fileInput: any = React.createRef(); // todo type
    const match = useRouteMatch();
    const history = useHistory();

    const { setUrl, setImage, image, setResultData, setClothCategories, setClothCategoriesRus, error, setError } = sectionContext;

    const onUrlChange = (url: string) => {
        setLinkValue(url);
        setError(null);
    };

    const onNextClick = () => {
        if (fileValue) {
            let reader = new FileReader();
            reader.onload = () => {
                const result = (reader.result as string).split('base64,')[1];
                setImage(result);
                gotoNextScreen();
            };
            reader.readAsDataURL(fileValue as File); //base64
        } else if (linkValue) {
            setUrl(linkValue);
            gotoNextScreen();
        }
    };

    const gotoNextScreen = () => {
        setError(null);
        history.push(`${match.url}/questions`);
    };

    const renderError = (error: Error) => {
        switch (true) {
            case error instanceof InvalidImageUrlError:
                return (
                    <div className={_css[prefix + '__errors']}>
                        <ErrorPresenter
                            text="Вставьте ссылку на конкретное изображение"
                            tooltipText="Сделайте длинный тап по изображению и выберите действие “копировать изображение” или “скопировать”."
                            tooltipTextDesktop="Кликнете правой кнопкой по изображению и выберите действие “копировать URL изображения” или “скопировать адрес изображения”."
                        ></ErrorPresenter>
                    </div>
                );
            default:
                return null;
        }
    };

    useEffect(() => {
        setUrl('');
        setImage(null);
        setResultData(null);
        setClothCategories([]);
        setClothCategoriesRus([]);
    }, []);

    return (
        <section className={prefix + '__layout ' + _css[prefix] + ' ' + css[prefix]}>
            <div className={prefix + '__gradients'}>
                <div className={prefix + '__red-wrapper'}>
                    <div className={prefix + '__red'}></div>
                </div>
                <div className={prefix + '__red2-wrapper'}>
                    <div className={prefix + '__red2'}></div>
                </div>
                <div className={prefix + '__blue-wrapper'}>
                    <div className={prefix + '__blue'}></div>
                </div>
                <div className={prefix + '__gradients-overlay'}></div>
            </div>
            <div className={prefix + '__layout-center'}>
                <div className={_css[prefix + '__content']}>
                    <h2 className={prefix + '__page-title-left ' + prefix + '__page-title-left--centered ' + _css[prefix + '__page-title']}>{pageTitle}</h2>
                    <div className={prefix + '__layout-facade-image ' + _css[prefix + '__facade-image']}>{facadeSvg}</div>

                    {!fileValue ? (
                        <input
                            id="file"
                            className={_css[prefix + '__custom-file-input']}
                            type="file"
                            ref={fileInput}
                            onChange={() => {
                                setError(null);
                                setFileValue(fileInput?.current?.files[0]);
                                onFileOrUrlLoaded();
                            }}
                        />
                    ) : (
                        <div className={_css[prefix + '__file-value']}>
                            <div className={_css[prefix + '__file-image']} style={{ backgroundImage: `url(${URL.createObjectURL(fileValue)})` }}></div>
                            <span className={_css[prefix + '__file-value-text']}>{fileValue?.name}</span>
                            <button
                                className={prefix + '__button-close'}
                                type="button"
                                onClick={() => {
                                    setFileValue(null);
                                }}
                            ></button>
                        </div>
                    )}

                    <div className={_css[prefix + '__or-text']}>или</div>
                    <input
                        className={
                            prefix +
                            '__input-text ' +
                            _css[prefix + '__input-text-link'] +
                            (linkValue ? ' ' + _css[prefix + '__input-text-link--valued'] : '') +
                            ' ' +
                            (error && error instanceof InvalidImageUrlError ? _css[prefix + '__input-text-link--error'] : '')
                        }
                        type="text"
                        placeholder="Вставить ссылку на фото"
                        value={linkValue}
                        disabled={!!fileValue}
                        onClick={(e) => onFileOrUrlLoaded()}
                        onChange={(e) => onUrlChange(e.target.value)}
                    />
                    <label htmlFor="inputLinkToImg"></label>
                    {!error ? null : renderError(error)}
                    <button className={_css[prefix + '__button-find']} type="button" disabled={!(linkValue || fileValue)} onClick={() => onNextClick()}>
                        Найти
                    </button>
                </div>
            </div>
            <div className={prefix + '__layout-right ' + prefix + '__layout-right--svg-container ' + _css[prefix + '__layout-right']}>{facadeDesktopSvg}</div>
        </section>
    );
};

export default ScreenFileUpload;
