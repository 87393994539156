import { LStorage } from './constants';

class Utils {
    public static selectItem<T>(value: T, selectedItems: Array<T>, setSelectedItems: Function) {
        if (selectedItems.includes(value)) {
            setSelectedItems(selectedItems.filter((_value) => _value !== value));
        } else {
            setSelectedItems([...selectedItems, value]);
        }
    }

    public static getScreenType(): 'MOBILE' | 'DESKTOP' {
        return document.body.clientWidth >= 1024 ? 'DESKTOP' : 'MOBILE';
    }

    public static openUrls(urls: Array<string>) {
        urls.forEach((url) => window.open(url, '_blank'));
    }

    public static isProductLiked(productId: number) {
        const likedProductIdsStorage = localStorage.getItem(LStorage.likedProductIds);
        const likedProductIds = likedProductIdsStorage ? JSON.parse(likedProductIdsStorage) : [];
        return likedProductIds?.indexOf(productId) !== -1;
    }

    public static toggleLikeInStorage(productId: number) {
        const likedProductIdsStorage = localStorage.getItem(LStorage.likedProductIds);
        if (likedProductIdsStorage) {
            let likedProductIds: Array<number> = JSON.parse(likedProductIdsStorage);
            const newProductIds = likedProductIds.includes(productId)
                ? likedProductIds.filter((_productId) => _productId !== productId)
                : [...likedProductIds, productId];
            localStorage.setItem(LStorage.likedProductIds, JSON.stringify(newProductIds));
        } else {
            localStorage.setItem(LStorage.likedProductIds, JSON.stringify([productId]));
        }
    }

    public static isElementInViewport(el: Element) {
        if (!el.getBoundingClientRect) {
            return true;
        }

        const rect = el.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }
}

export default Utils;
