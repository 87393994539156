import { useContext } from 'react';
import { useHistory } from 'react-router';
import { TotalLookContext } from '../../../../js/context';
import { IResponseImageProcessing } from '../../../../models/models';
import ScreenPreferences from '../../common/preferences/ScreenPreferences';

const ScreenTotalLookPrefs = () => {
    const history = useHistory();

    const onGotoNextScreen = (resultData: IResponseImageProcessing | null | undefined, clothCategories: Array<string>) => {
        const resultDataItems = resultData ? resultData.items.filter((item) => clothCategories.find((category) => category === item.category_label)) : [];
        const productId = resultDataItems?.[0]?.products?.[0]?.id;

        history.push(`/total-look/best-combos/${productId}?from=prefs`);
    };

    return (
        <ScreenPreferences
            onGotoNext={onGotoNextScreen}
            sectionContext={useContext(TotalLookContext)}
            isCategorySingle={true}
            pageTitle={
                <>
                    Что именно вас
                    <br />
                    интересует на&nbsp;фото?
                </>
            }
            pageSubtitle="Выберите одну или несколько категорий на фото,  в которых необходимо осуществить поиск. Если хотите выбрать все категории — нажмите “выбрать всё”."
        />
    );
};

export default ScreenTotalLookPrefs;
