import React, { Ref, useContext, useEffect, useRef, useState } from 'react';
import GarderoboApi, { AppError } from '../../../../js/api';
import { deviceType, LStorageController, prefix } from '../../../../js/constants';
import { GlobalContext, SimilarContext } from '../../../../js/context';
import { IProcessedImageItem, IProductInfo, IResponseImageProcessing } from '../../../../models/models';
import { PricesType, pricesTypesObj } from '../../../../models/models-front';
import ButtonBack from '../../../ui/button-back/ButtonBack';
import Dropdown from '../../../ui/dropdown/Dropdown';
import ProductCard from '../../../ui/product-card/ProductCard';
import ProductCarousel from '../../../ui/product-carousel/ProductCarousel';
import ProductSlider from '../../../ui/product-slider/ProductSlider';
import css from './ScreenSimilarResults.module.scss';

const getProcessedResultData = (resultData: IResponseImageProcessing | null | undefined, clothCategories: Array<string>) => {
    return resultData ? resultData.items.filter((item) => item.products?.length && clothCategories.find((category) => category === item.category_label)) : [];
};

const ScreenSimilarResults = () => {
    //const userPic = 'https://cdn-images.farfetch-contents.com/16/93/17/09/16931709_34526632_1000.jpg';
    const [productsPerfect, setProductsPerfect] = useState<Array<IProductInfo>>([]);

    const [selectedPrice, setSelectedPrice] = useState<PricesType>(LStorageController.getPricesType());

    const { url, image, resultData, setResultData, clothCategories, clothCategoriesRus } = useContext(SimilarContext);

    const [resultDataItems, setResultDataItems] = useState<Array<IProcessedImageItem>>(getProcessedResultData(resultData, clothCategories));
    //console.log('url img', url, image, categoryGroups);

    const { setError, setIsPending } = useContext(GlobalContext);

    const processFilteredData = (categoryGroups: Array<IProcessedImageItem>) => {
        if (!categoryGroups?.length) return;

        let productsPerfectCount = 4;
        let productIndex = 0;
        const bestProducts: Array<IProductInfo> = [];
        for (let j = 0; j < 100; j++) {
            // todo - not 100
            for (let i = 0; i < categoryGroups.length; i++) {
                if (categoryGroups[i].products[j]) {
                    bestProducts.push(categoryGroups[i].products[j]);
                } else {
                    continue;
                }

                productsPerfectCount--;

                if (productsPerfectCount == 0) {
                    break;
                }
            }
            if (productsPerfectCount == 0) {
                break;
            }
        }
        setProductsPerfect(bestProducts);
    };

    const onPriceChange = (pricecode: PricesType) => {
        console.log('clothCategories', clothCategories);
        setIsPending(true);
        setSelectedPrice(pricecode);
        GarderoboApi.processImage(
            LStorageController.getGender(),
            LStorageController.getClothSizes(),
            LStorageController.getShoesSizes(),
            pricecode,
            LStorageController.getStyle(),
            clothCategoriesRus,
            //LStorageController.getCategoryGroups(),
            url || undefined,
            image || undefined
        )
            .then((data) => {
                setResultData(data); // todo - надо?
                setResultDataItems(getProcessedResultData(data, clothCategories));
                processFilteredData(data.items);
            })
            .catch((error: AppError) => setError(error.getErrorText()))
            .finally(() => setIsPending(false));
    };

    useEffect(() => LStorageController.setPricesType(selectedPrice), [selectedPrice]);

    useEffect(() => {
        processFilteredData(resultDataItems);
    }, []);

    return (
        <section className={css[prefix]}>
            <div className={css[prefix + '__user-picture-container']}>
                <div className={prefix + '__desktop'}>
                    <ButtonBack titleDesktop="К вопросам" />
                </div>
                <img className={css[prefix + '__user-picture']} src={resultData?.url_prepared} alt="Загруженное фото" />
            </div>
            <section className={css[prefix + '__section-perfect']}>
                <h3 className={css[prefix + '__section-perfect-title']}>Идеально подходит</h3>
                {!productsPerfect?.length ? (
                    <div className={prefix + '__center-box'}>Варианты не найдены</div>
                ) : deviceType === 'MOBILE' ? (
                    <ProductCarousel products={productsPerfect} />
                ) : (
                    <ul className={css[prefix + '__products-list']}>
                        {productsPerfect.map((product) => (
                            <li key={product.id}>
                                <ProductCard product={product} urlPath="similar" hasLike={true} />
                            </li>
                        ))}
                    </ul>
                )}
            </section>
            <section className={css[prefix + '__section-more']}>
                <header className={css[prefix + '__section-more-header']}>
                    <h3 className={css[prefix + '__section-more-title']}>Больше вариантов</h3>
                    <div className={prefix + '__mobile' + ' ' + css[prefix + '__prices-dropdown']}>
                        <Dropdown options={pricesTypesObj} selectedItemCode={selectedPrice} onSelect={(priceCode: PricesType) => onPriceChange(priceCode)} />
                    </div>
                    <div className={prefix + '__desktop'}>
                        <ul className={css[prefix + '__prices']}>
                            {pricesTypesObj.map((priceItem, index) => {
                                const selected = selectedPrice === priceItem.code;
                                return (
                                    <li
                                        key={index}
                                        className={prefix + '__select-box-item' + ' ' + (selected ? ' ' + prefix + '__select-box-item--selected' : '')}
                                        onClick={() => onPriceChange(priceItem.code)}
                                    >
                                        {priceItem.title}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </header>
                <div className={css[prefix + '__categories']}>
                    {!resultDataItems.find(({ products }) => products?.length) ? (
                        <div className={prefix + '__center-box'}>Варианты не найдены</div>
                    ) : (
                        resultDataItems.map(({ category_label, type, products }, index) => (
                            <div key={index} className={css[prefix + '__category']}>
                                {deviceType === 'MOBILE' ? (
                                    <>
                                        <h4 className={css[prefix + '__category-name']}>{type}</h4>
                                        <ProductCarousel products={products} />
                                    </>
                                ) : (
                                    <ProductSlider products={products} css={css} categoryTitle={type} />
                                )}
                            </div>
                        ))
                    )}
                </div>
            </section>
        </section>
    );
};

export default ScreenSimilarResults;
