import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { IProductInfo } from '../../../models/models';
import ProductCard from '../product-card/ProductCard';
import { prefix } from '../../../js/constants';
import css from './ProductCarousel.module.scss';

const ProductCarousel = ({ products }: { products: Array<IProductInfo> }) => {
    return (
        <div className={css[prefix]}>
            <Carousel
                className={css[prefix + '__carousel']}
                centerMode={true}
                centerSlidePercentage={60}
                infiniteLoop={false}
                showArrows={false}
                emulateTouch={true}
                showThumbs={false}
                showIndicators={false}
                showStatus={false}
            >
                {products.map((product) => (
                    <ProductCard key={product.id} product={product} urlPath="similar" hasLike={true} customCss={css} />
                ))}
            </Carousel>
        </div>
    );
};

export default ProductCarousel;
