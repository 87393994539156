import { QuestionType } from '../../../../models/models-front';
import ScreenQuestions from '../../common/questions/ScreenQuestions';

const ScreenRecommendedQues = () => {
    return (
        <ScreenQuestions
            pageTitle="Давай познакомимся"
            pageSubtitle="Еще парочку личных вопросов, чтобы добавить индивидуальности и учесть наличие твоих размеров
    в магазине"
            buttonBackTitle="К выбору категорий"
            buttonNextTitle="Далее"
            nextUrlPart="likes"
            options={{ [QuestionType.SEX]: true, [QuestionType.AGE]: true, [QuestionType.CLOTHES_SIZES]: true, [QuestionType.SHOES_SIZES]: true }}
        />
    );
};

export default ScreenRecommendedQues;
