import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Actions from '../../../../js/actions';
import GarderoboApi, { AppError } from '../../../../js/api';
import { LStorageController, prefix } from '../../../../js/constants';
import { GlobalContext } from '../../../../js/context';
import Utils from '../../../../js/utils';
import { IProductInfo } from '../../../../models/models';
import { ISimilarProducts } from '../../../../models/models-front';
import ButtonBack from '../../../ui/button-back/ButtonBack';
import ButtonShare from '../../../ui/button-share/ButtonShare';
import ProductCard from '../../../ui/product-card/ProductCard';
import css from './ScreenLookSetup.module.scss';

const ScreenLookSetup = () => {
    const urlPath = 'popular';
    const pageTitle = 'Докручивай образ под себя';
    const [products, setProducts] = useState<Array<IProductInfo>>();
    const { lookId } = useParams<{ lookId: string }>();

    const [similarProducts, setSimilarProducts] = useState<ISimilarProducts>({});
    const [lookItemId, setLookItemId] = useState<number>(); // id товара в луке, для которого был запущен поиск похожих

    const { setError, setIsPending } = useContext(GlobalContext);

    const queryParams = new URLSearchParams(window.location.search);

    const gotoNextProduct = (productId: number, setIsPending: React.Dispatch<React.SetStateAction<boolean>>, direction: number) => {
        if (products) {
            Actions.gotoNextProduct(productId, products, similarProducts, setSimilarProducts, setLookItemId, setIsPending, direction);
        }
    };

    useEffect(() => {
        if (products && lookItemId) {
            Actions.updateProductsOnGotoNextProduct(products, lookItemId, similarProducts, setProducts);
        }
    }, [similarProducts]);

    useEffect(() => {
        setIsPending(true);

        const paramIds = queryParams.get('ids');
        if (paramIds) {
            const productsIdsFromUrl = paramIds.split(',');
            // если зашли из кнопки "поделиться" - загружаем все товары по id, которые указаны в url-е
            if (productsIdsFromUrl) {
                const productsPromises = productsIdsFromUrl.map((productIdFromUrl) => GarderoboApi.getProductInfo(+productIdFromUrl).catch(() => null));
                Promise.all(productsPromises)
                    .then((productsFromUrl) => {
                        const loadedProducts = productsFromUrl.filter((_product) => _product) as Array<IProductInfo>;
                        setProducts(loadedProducts);
                    })
                    .finally(() => setIsPending(false));
            }
        } else {
            GarderoboApi.getLook(+lookId, LStorageController.getStyle(), LStorageController.getGender())
                .then((data) => {
                    setProducts(data?.products);
                })
                .catch((error: AppError) => {
                    setError(error.getErrorText());
                    setProducts([]);
                })
                .finally(() => setIsPending(false));
        }
    }, []);

    const linkBuyLook = (
        <button
            className={prefix + '__button-main-black ' + css[prefix + '__btn-buy']}
            type="button"
            onClick={() => {
                if (products?.length) {
                    Utils.openUrls(products.map((product) => product.url));
                }
            }}
        >
            Купить весь образ
        </button>
    );

    const ids = products?.map((product) => product.id).join(',');
    const idsParam = ids ? `&ids=${ids}` : '';
    const url = `${process.env.REACT_APP_DOMAIN}${window.location.pathname}?${idsParam}`;
    const btnShare = <ButtonShare url={encodeURIComponent(url)} title={pageTitle} />;

    return (
        <section className={prefix + '__layout ' + css[prefix]}>
            <div className={prefix + '__layout-center ' + css[prefix + '__layout-center']}>
                {!products ? null : !products.length ? (
                    <div className={prefix + '__center-box'}>Лук не найден</div>
                ) : (
                    <>
                        <div className={prefix + '__mobile'}>
                            <h2 className={prefix + '__page-title-left'}>{pageTitle}</h2>
                            <div className={css[prefix + '__user-product']}>
                                <ProductCard product={products[0]} hasLike={true} urlPath={urlPath} />
                                <div className={css[prefix + '__user-product-options']}>
                                    <p className={css[prefix + '__user-product-text']}>
                                        Выбирай готовые образы или заменяй отдельные вещи в&nbsp;них, чтобы посмотреть больше вариантов
                                    </p>
                                    {/* <div className={prefix + '__dropdown-wrapper'}>
                                        <Dropdown options={pricesTypesObj} />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <ul className={css[prefix + '__products']}>
                            {products.map((product) => (
                                <li key={product.id} className={css[prefix + '__product']}>
                                    <ProductCard
                                        product={product}
                                        hasRefresh={true}
                                        onRefresh={(_product, _setIsPending, direction) => gotoNextProduct(_product?.id, _setIsPending, direction)}
                                        hasLike={true}
                                        urlPath={urlPath}
                                    />
                                </li>
                            ))}
                        </ul>
                        <div className={prefix + '__mobile' + ' ' + css[prefix + '__btns']}>
                            {linkBuyLook}
                            {btnShare}
                        </div>
                        <div className={prefix + '__mobile ' + css[prefix + '__sticky-controls']}>
                            {/* {btnPrev}
                            {btnNext} */}
                        </div>
                    </>
                )}
            </div>
            <div className={prefix + '__layout-right ' + prefix + '__layout-right--with-nav ' + css[prefix + '__layout-right']}>
                <div>
                    <ButtonBack titleDesktop="Назад" cssClass={css[prefix + '__btn-back']} />
                    <div className={css[prefix + '__main-block']}>
                        <header className={css[prefix + '__main-block-header']}>
                            <h2 className={prefix + '__page-title-left'}>{pageTitle}</h2>
                            <h4 className={prefix + '__subtitle'}>
                                Выбирай готовые образы или заменяй отдельные вещи в них, чтобы посмотреть больше вариантов
                            </h4>
                        </header>
                        <div className={css[prefix + '__main-block-card-wrapper']}>
                            {products?.[0] ? <ProductCard product={products?.[0]} hasLike={true} urlPath={urlPath} /> : 'no product'}
                        </div>
                    </div>
                </div>
                <div className={css[prefix + '__controls']}>
                    {linkBuyLook}
                    {btnShare}
                </div>
            </div>
        </section>
    );
};

export default ScreenLookSetup;
