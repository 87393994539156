import { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import GarderoboApi, { AppError } from '../../../js/api';
import { prefix } from '../../../js/constants';
import { GlobalContext } from '../../../js/context';
import { ILook } from '../../../models/models';
import { Gender, PricesType, Style } from '../../../models/models-front';
import Filters from '../../ui/filters/Filters';
import LookCollage from './look-collage/LookCollage';
import css from './ScreenPopular.module.scss';

const ScreenPopular = ({ isHomeMode = false }: { isHomeMode?: boolean }) => {
    const partSize = 8;

    const [productsPartCount, setProductsPartCount] = useState<number>(partSize);
    const [allLooks, setAllLooks] = useState<Array<ILook>>([]);
    const [looks, setLooks] = useState<Array<ILook>>();

    const { setError, setIsPending } = useContext(GlobalContext);

    const onFilter = (priceType: PricesType, gender: Gender, style: Style) => {
        setIsPending(true);
        GarderoboApi.getPopularLooks(priceType, style, gender)
            .then((data) => {
                setLooks(data.looks?.slice(0, productsPartCount));
                setAllLooks(data.looks);
            })
            .catch((error: AppError) => {
                setError(error.getErrorText());
                setLooks([]);
                setAllLooks([]);
            })
            .finally(() => {
                setProductsPartCount(partSize);
                setIsPending(false);
            });
    };

    const showMore = () => {
        const newPartCount = productsPartCount + partSize;
        setProductsPartCount(newPartCount);
        if (allLooks.length >= newPartCount) {
            setLooks(allLooks.slice(0, newPartCount));
        } else {
            setLooks(allLooks);
        }
    };

    return (
        <section className={prefix + '__layout' + ' ' + css[prefix]}>
            <div className={prefix + '__layout-center' + ' ' + css[prefix + '__layout-center']}>
                <h2 className={prefix + '__page-title-left' + ' ' + css[prefix + '__page-title-left']}>Узнай, что сейчас в тренде</h2>
                <Filters onFilterChanged={onFilter} />

                {!looks ? null : !looks.length ? (
                    <div className={prefix + '__center-box'}>Луки не найдены</div>
                ) : (
                    <>
                        <ul className={css[prefix + '__looks']}>
                            {looks.map((look) => (
                                <li key={look.look_id} className={css[prefix + '__look']}>
                                    <LookCollage look={look} />
                                </li>
                            ))}
                        </ul>
                    </>
                )}

                {allLooks.length <= productsPartCount ? null : (
                    <div className={css[prefix + '__controls']}>
                        <button className={prefix + '__button-main-black' + ' ' + css[prefix + '__btn']} type="button" onClick={showMore}>
                            Показать еще ({allLooks.length - productsPartCount})
                        </button>
                    </div>
                )}
            </div>
        </section>
    );
};

export default ScreenPopular;
