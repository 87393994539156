import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader    // todo - doubled calls?
import { Carousel } from 'react-responsive-carousel';
import { prefix } from '../../../js/constants';
import css from './ProductImagesCarousel.module.scss';

const ProductImagesCarousel = ({
    images,
    setCurrentPic,
    currentPic,
}: {
    images: Array<string>;
    setCurrentPic: React.Dispatch<React.SetStateAction<string | undefined>>;
    currentPic: string | undefined;
}) => {
    const currImageIndex = images.findIndex((el) => el === currentPic);
    const selectedItem = currentPic ? (currImageIndex !== -1 ? currImageIndex : 0) : 0;
    return (
        <div className={css[prefix]}>
            <Carousel
                className={css[prefix + '__carousel']}
                infiniteLoop={false}
                showArrows={false}
                emulateTouch={true}
                showThumbs={false}
                showStatus={false}
                selectedItem={selectedItem}
                onChange={(index) => {
                    setCurrentPic(images[index]);
                }}
            >
                {images.map((url, index) => (
                    <img key={index} src={url} alt={`Фото товара ${index}`} />
                ))}
            </Carousel>
        </div>
    );
};

export default ProductImagesCarousel;
