import '../src/styles/fonts.scss';
import '../src/styles/main.scss';
import '../src/styles/buttons.scss';
import '../src/styles/gradients.scss';
import '../src/styles/layout.scss';
import { Route, NavLink, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import { MAIN_MENU } from './routes';
import { useEffect, useState } from 'react';
import ScreenHome from './components/screens/home/ScreenHome';
import ScreenSimilarResults from './components/screens/similar/results/ScreenSimilarResults';
import ProductPage from './components/ui/product-page/ProductPage';
import ScreenBestCombos from './components/screens/common/best-combos/ScreenBestCombos';
import ButtonBack from './components/ui/button-back/ButtonBack';
import classes from './App.module.scss';
import { prefix } from './js/constants';
import ScreenRecommendedPrefs from './components/screens/recommended/preferences/ScreenRecommendedPrefs';
import ScreenRecommendedQues from './components/screens/recommended/questions/ScreenRecommendedQues';
import ScreenRecommendedLikes from './components/screens/recommended/likes/ScreenRecommendedLikes';
import ScreenLookSetup from './components/screens/popular/look-setup/ScreenLookSetup';
import ScreenRecommendedResults from './components/screens/recommended/results/ScreenRecommendedResults';
import ScreenTotalLookQues from './components/screens/total-look/questions/ScreenTotalLookQues';
import ScreenTotalLookPrefs from './components/screens/total-look/preferences/ScreenTotalLookPrefs';
import ScreenSimilarQues from './components/screens/similar/questions/ScreenSimilarQues';
import ScreenSimilarPrefs from './components/screens/similar/preferences/ScreenSimilarPrefs';
import GarderoboApi, { AppError } from './js/api';
import { GlobalContext, IErrorExtended, ILikedProductItem, SimilarContext, TotalLookContext } from './js/context';
import { IResponseImageProcessing } from './models/models';
import Preloader from './components/ui/preloader/Preloader';
import React from 'react';

function App() {
    const [isMenuOpened, setIsMenuOpened] = useState(false);
    const history = useHistory();
    const search = useLocation().search;
    const category = new URLSearchParams(search).get('category') || undefined; //todo

    let isHome = history.location.pathname === '/';
    const line = <hr className={'garderobo__line ' + classes[prefix + '__line']} />;

    const isWhiteCopyright =
        history.location.pathname === '/similar' ||
        history.location.pathname === '/total-look' ||
        history.location.pathname === '/similar/' ||
        history.location.pathname === '/total-look/';

    const [didMount, setDidMount] = useState(false);

    useEffect(() => {
        setDidMount(true);
    }, []);

    const header = (backBtn: boolean, titleMobile = 'Назад') => {
        if (!didMount) {
            window.scrollTo(0, 0);
        }

        return (
            <div className="garderobo__mobile-header">
                {backBtn ? (
                    <ButtonBack titleMobile={titleMobile} />
                ) : (
                    <NavLink to="/">
                        <div className="garderobo__logo garderobo__mobile-header__logo"></div>
                    </NavLink>
                )}
                <button className="garderobo_header-btn-burger" type="button" onClick={() => setIsMenuOpened(true)}></button>
            </div>
        );
    };

    const footer = (
        <div className={prefix + '__copyright' + (isWhiteCopyright ? ' ' + prefix + '__copyright--white' : '')}>
            <a className={prefix + '__copyright-title'} href="https://garderobo.ai" target="_blank">
                GardeRobo для бизнеса
            </a>
            <div className={prefix + '__copyright-domain'}>&copy;2021 GardeRobo.ai</div>
        </div>
    );

    useEffect(() => {
        GarderoboApi.startSession()
            .then((data) => console.log('data', data))
            .catch((e: AppError) => setError(e.getErrorText()));
    }, []);

    //================================================> similar context
    const [similarUrl, setSimilarUrl] = useState<string>('');
    const [similarImage, setSimilarImage] = useState<string | null>(null);
    const [similarResultData, setSimilarResultData] = useState<IResponseImageProcessing | null>(null);
    const [similarError, setSimilarError] = useState<Error | null>(null);
    const [similarClothCategories, setSimilarClothCategories] = useState<Array<string>>([]);
    const [similarClothCategoriesRus, setSimilarClothCategoriesRus] = useState<Array<string>>([]);

    const similarContextValue = {
        url: similarUrl,
        setUrl: setSimilarUrl,
        image: similarImage,
        setImage: setSimilarImage,
        clothCategories: similarClothCategories,
        setClothCategories: setSimilarClothCategories,
        clothCategoriesRus: similarClothCategoriesRus,
        setClothCategoriesRus: setSimilarClothCategoriesRus,
        resultData: similarResultData,
        setResultData: setSimilarResultData,
        error: similarError,
        setError: setSimilarError,
    };

    //================================================> total-look context
    const [totalUrl, setTotalUrl] = useState<string>('');
    const [totalImage, setTotalImage] = useState<string | null>(null);
    const [totalResultData, setTotalResultData] = useState<IResponseImageProcessing | null>(null);
    const [totalError, setTotalError] = useState<Error | null>(null);
    const [totalClothCategories, setTotalClothCategories] = useState<Array<string>>([]);
    const [totalClothCategoriesRus, setTotalClothCategoriesRus] = useState<Array<string>>([]);

    const totalContextValue = {
        url: totalUrl,
        setUrl: setTotalUrl,
        image: totalImage,
        setImage: setTotalImage,
        clothCategories: totalClothCategories,
        setClothCategories: setTotalClothCategories,
        clothCategoriesRus: totalClothCategoriesRus,
        setClothCategoriesRus: setTotalClothCategoriesRus,
        resultData: totalResultData,
        setResultData: setTotalResultData,
        error: totalError,
        setError: setTotalError,
    };

    //================================================> global context
    const [error, setError] = useState<string | IErrorExtended>('');
    const [isPending, setIsPending] = useState(false);
    const [likedProductId, setLikedProductId] = useState<ILikedProductItem | null>(null);

    return (
        <GlobalContext.Provider value={{ error, setError, isPending, setIsPending, likedProductId, setLikedProductId }}>
            <div className={prefix}>
                <div className={'garderobo__left-panel garderobo__left-panel' + (isMenuOpened ? '--opened' : '')}>
                    <header className="garderobo__header">
                        <button className="garderobo__header-btn-close" type="button" onClick={() => setIsMenuOpened(false)}></button>
                        <div className="garderobo__logo-wrapper">
                            <NavLink to="/" onClick={() => setIsMenuOpened(false)}>
                                <div className="garderobo__logo"></div>
                            </NavLink>
                        </div>
                        {line}

                        <nav className="garderobo__menu">
                            <ul className={'garderobo__menu-list' + (isHome ? ' garderobo__menu-list--home' : '')}>
                                {MAIN_MENU.map(({ title, link }, index) => (
                                    <li className="garderobo__menu-item" key={index}>
                                        <NavLink
                                            className="garderobo__menu-item-link"
                                            activeClassName="garderobo__menu-item-link--active"
                                            to={link}
                                            onClick={() => setIsMenuOpened(false)}
                                        >
                                            {title}
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </header>

                    <footer className="garderobo__footer">
                        {/* <div className="garderobo__menu-social">
                            <ul className="garderobo__menu-social-list">
                                <li className="garderobo__menu-social-item">
                                    <a className="garderobo__menu-social-item-link" href="#">
                                        Facebook
                                    </a>
                                </li>
                                <li className="garderobo__menu-social-item">
                                    <a className="garderobo__menu-social-item-link" href="#">
                                        Instagram
                                    </a>
                                </li>
                                <li className="garderobo__menu-social-item">
                                    <a className="garderobo__menu-social-item-link" href="#">
                                        Tiktok
                                    </a>
                                </li>
                            </ul>
                        </div> */}
                        {line}
                        {footer}
                    </footer>
                </div>
                <main className="garderobo__main">
                    <Preloader isPending={isPending} type="global" />
                    <Switch>
                        <Route path="/" exact>
                            {header(false)}
                            <ScreenHome />
                        </Route>

                        {/* similar */}
                        <Route path="/similar/preferences" exact>
                            {header(true)}
                            <SimilarContext.Provider value={similarContextValue}>
                                <ScreenSimilarPrefs />
                            </SimilarContext.Provider>
                        </Route>
                        <Route path="/similar/questions" exact>
                            {header(true)}
                            <ScreenSimilarQues />
                        </Route>
                        <Route path="/similar/results" exact>
                            {header(true)}
                            <SimilarContext.Provider value={similarContextValue}>
                                <ScreenSimilarResults />
                            </SimilarContext.Provider>
                        </Route>
                        <Route path="/similar/results" exact>
                            <Redirect to="/similar/results/"></Redirect>
                        </Route>

                        {/* total-look */}
                        <Route path="/total-look/questions" exact>
                            {header(true)}
                            <ScreenTotalLookQues />
                        </Route>
                        <Route path="/total-look/preferences" exact>
                            {header(true)}
                            <TotalLookContext.Provider value={totalContextValue}>
                                <ScreenTotalLookPrefs />
                            </TotalLookContext.Provider>
                        </Route>
                        <Route path="/total-look/best-combos/:productId" exact>
                            {header(true)}
                            <TotalLookContext.Provider value={totalContextValue}>
                                <ScreenBestCombos />
                            </TotalLookContext.Provider>
                        </Route>

                        {/* recommended */}
                        <Route path="/recommended/preferences" exact>
                            {header(true)}
                            <ScreenRecommendedPrefs />
                        </Route>
                        <Route path="/recommended/questions" exact>
                            {header(true)}
                            <ScreenRecommendedQues />
                        </Route>
                        <Route path="/recommended/likes" exact>
                            {header(true, category)}
                            <ScreenRecommendedLikes />
                        </Route>
                        <Route path="/recommended/results" exact>
                            {header(true, category)}
                            <ScreenRecommendedResults />
                        </Route>
                        <Route path="/recommended/products" exact>
                            {header(true, category)}
                            <ScreenRecommendedResults />
                        </Route>

                        {/* popular */}
                        <Route path="/popular/:lookId" exact>
                            {header(true)}
                            <ScreenLookSetup />
                        </Route>
                        {/* add child routes to routes.tsx */}

                        {MAIN_MENU.map(({ link, component, name }, index) => (
                            <Route path={link} exact key={index}>
                                <>
                                    {header(false)}
                                    {name === 'similar' ? (
                                        <SimilarContext.Provider value={similarContextValue}>{component(null)}</SimilarContext.Provider>
                                    ) : name === 'total-look' ? (
                                        <TotalLookContext.Provider value={totalContextValue}>{component(null)}</TotalLookContext.Provider>
                                    ) : (
                                        component(null)
                                    )}
                                </>
                            </Route>
                        ))}
                        {MAIN_MENU.map(({ name }, index) => (
                            <Route
                                path={`/${name}/products/:productId`}
                                exact
                                key={index}
                                render={(props) => (
                                    <>
                                        {header(true)}
                                        <ProductPage key={props.match.params.productId} {...props} />
                                    </>
                                )}
                            ></Route>
                        ))}

                        <Redirect to="/" />
                    </Switch>
                    <div className={prefix + '__mobile'}>{footer}</div>
                </main>
                <div className={prefix + '__error-box' + (error ? ' ' + prefix + '__error-box--opened' : '')}>
                    <div className={prefix + '__error-box-content'}>
                        <div className={prefix + '__error-box-content-text'}>{(error as IErrorExtended)?.text || error}</div>
                        {(error as IErrorExtended)?.noOkButton ? null : (
                            <button type="button" className={prefix + '__button-main-black'} onClick={() => setError('')}>
                                {(error as IErrorExtended)?.okBtnText || 'Ok'}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </GlobalContext.Provider>
    );
}

// todo - what for is React.memo
export default React.memo(App);
