import { useEffect, useRef, useState } from 'react';
import { deviceType, prefix } from '../../../js/constants';
import { IProductInfo } from '../../../models/models';
import ProductCard from '../product-card/ProductCard';
import _css from './ProductSlider.module.scss';

const goPrev = (
    partStart: number,
    setPartStart: React.Dispatch<React.SetStateAction<number>>,
    products: Array<IProductInfo>,
    setProductsPart: React.Dispatch<React.SetStateAction<IProductInfo[]>>
) => {
    let suggestedStart = partStart - 4;
    if (suggestedStart < 0) {
        suggestedStart = 0;
    }
    setPartStart(suggestedStart);
    setProductsPart(products.slice(suggestedStart, suggestedStart + 4));
};

const goNext = (
    partStart: number,
    setPartStart: React.Dispatch<React.SetStateAction<number>>,
    products: Array<IProductInfo>,
    setProductsPart: React.Dispatch<React.SetStateAction<IProductInfo[]>>
) => {
    let suggestedStart = partStart + 4;
    if (suggestedStart + 4 > products.length) {
        suggestedStart = products.length - 4;
    }
    setPartStart(suggestedStart);
    setProductsPart(products.slice(suggestedStart, suggestedStart + 4));
};

const ProductSlider = ({ products, css, categoryTitle }: { products: Array<IProductInfo>; css: any; categoryTitle?: string }) => {
    const productRef = useRef<HTMLLIElement>(null);
    const productsListRef = useRef<HTMLUListElement>(null);

    const [partStart, setPartStart] = useState<number>(0);
    const [productsPart, setProductsPart] = useState<Array<IProductInfo>>(products.slice(0, 4));

    useEffect(() => {
        setProductsPart(products.slice(0, 4));
        setPartStart(0);
    }, [products]);

    return (
        <div className={_css[prefix]}>
            <div className={_css[prefix + '__title-nav'] + (categoryTitle ? ' ' + _css[prefix + '__title-nav--with-title'] : '')}>
                {!categoryTitle ? null : <h4 className={css[prefix + '__category-name']}>{categoryTitle}</h4>}

                {deviceType === 'MOBILE' ? null : (
                    <div className={_css[prefix + '__nav-btns']}>
                        <button
                            type="button"
                            className={prefix + '__button-nav ' + prefix + '__button-nav--prev'}
                            disabled={!products || products.length <= 4 || partStart <= 0}
                            onClick={() => goPrev(partStart, setPartStart, products, setProductsPart)}
                        ></button>
                        <button
                            type="button"
                            className={prefix + '__button-nav ' + prefix + '__button-nav--next'}
                            disabled={!products || products.length <= 4 || partStart + 4 >= products.length}
                            onClick={() => goNext(partStart, setPartStart, products, setProductsPart)}
                        ></button>
                    </div>
                )}
            </div>

            {deviceType === 'MOBILE' ? (
                <ul className={css[prefix + '__products-list'] + ' ' + _css[prefix + '__products-list']} ref={productsListRef}>
                    {products.map((product, index) => (
                        <li className={_css[prefix + '__products-list-item']} key={product.id} ref={index === 0 ? productRef : null}>
                            <ProductCard product={product} urlPath="similar" hasLike={true} customCss={_css} />
                        </li>
                    ))}
                </ul>
            ) : (
                <ul className={css[prefix + '__products-list'] + ' ' + _css[prefix + '__products-list']} ref={productsListRef}>
                    {productsPart.map((product) => (
                        <li className={_css[prefix + '__products-list-item']} key={product.id + '_desktop'}>
                            <ProductCard product={product} urlPath="similar" hasLike={true} customCss={_css} />
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default ProductSlider;
