import React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Actions from '../../../../js/actions';
import GarderoboApi, { AppError } from '../../../../js/api';
import { LStorageController, prefix } from '../../../../js/constants';
import { GlobalContext, TotalLookContext } from '../../../../js/context';
import Utils from '../../../../js/utils';
import { IProductInfo } from '../../../../models/models';
import { ISimilarProducts, PricesType, pricesTypesObj } from '../../../../models/models-front';
import ButtonBack from '../../../ui/button-back/ButtonBack';
import ButtonShare from '../../../ui/button-share/ButtonShare';
import Dropdown from '../../../ui/dropdown/Dropdown';
import ProductCard from '../../../ui/product-card/ProductCard';
import css from './ScreenBestCombos.module.scss';

// todo - simplify
/** загрузка похожих товаров в state */
const gotoNextProduct = (
    productId: number,
    setIsPending: React.Dispatch<React.SetStateAction<boolean>>,
    products: Array<IProductInfo>,
    similarProducts: ISimilarProducts,
    setSimilarProducts: React.Dispatch<React.SetStateAction<ISimilarProducts>>,
    setLookItemId: React.Dispatch<React.SetStateAction<number | undefined>>,
    direction: number
) => {
    if (products) {
        Actions.gotoNextProduct(productId, products, similarProducts, setSimilarProducts, setLookItemId, setIsPending, direction);
    }
};

const processLoadedProducts = (
    products: Array<IProductInfo>,
    setProducts: React.Dispatch<React.SetStateAction<Array<IProductInfo> | undefined>>,
    productId: string,
    setMainProduct: React.Dispatch<React.SetStateAction<IProductInfo | undefined>>
) => {
    setProducts(products?.filter((_product) => _product.id !== +productId));

    const _mainProduct = products?.find((_product) => _product.id === +productId);
    setMainProduct(_mainProduct || products?.[0]);
};

// todo - move all funcs before component

const ScreenBestCombos = () => {
    const urlPath = 'total-look';

    const [mainProduct, setMainProduct] = useState<IProductInfo>();
    const [products, setProducts] = useState<Array<IProductInfo>>();
    const [pageTitle, setPageTitle] = useState<string>('Готовый образ для тебя');
    const { productId } = useParams<{ productId: string }>();

    const { setError, setIsPending } = useContext(GlobalContext);

    const [similarProducts, setSimilarProducts] = useState<ISimilarProducts>({});
    const [lookItemId, setLookItemId] = useState<number>(); // id товара в луке, для которого был запущен поиск похожих

    const [lookIndex, setLookIndex] = useState(1);
    const [lookIndexMax, setLookIndexMax] = useState(0);

    const [price, setPrice] = useState<PricesType>(LStorageController.getPricesType());

    const [didMount, setDidMount] = useState(false);

    const getProductId = () => +productId;

    const queryParams = new URLSearchParams(window.location.search);
    const isRedirectedFromPrefs = queryParams.get('from') === 'prefs';

    const { resultData } = useContext(TotalLookContext);
    const [mainProductUrl, setMainProductUrl] = useState<string | undefined>(resultData?.url_prepared);

    useEffect(() => {
        setDidMount(true);
    }, []);

    /** смена текущего товара на похожий */
    useEffect(() => {
        if (products && lookItemId) {
            Actions.updateProductsOnGotoNextProduct(
                products, //.filter((_product) => _product.id !== getProductId()),
                lookItemId,
                similarProducts,
                setProducts
            );
        }
    }, [similarProducts]);

    const loadResults = (direction: 1 | -1) => {
        let index = lookIndex + direction;
        if (index < 1) {
            index = lookIndexMax || 1;
        }
        const _productId = getProductId();
        if (_productId) {
            setIsPending(true);
            GarderoboApi.getNextLook(
                _productId,
                index,
                LStorageController.getClothSizes(),
                LStorageController.getShoesSizes(),
                LStorageController.getPricesType(),
                LStorageController.getStyle(),
                LStorageController.getGender()
            )
                .then((data) => {
                    setLookIndex(lookIndex !== data.index ? data.index : index);
                    setProducts(data.products?.filter((_product) => _product.id !== _productId));
                })
                .catch((error: AppError) => {
                    setError(error.getErrorText());
                    setProducts([]);
                })
                .finally(() => setIsPending(false));
        } else {
            setProducts([]);
        }
    };

    useEffect(() => {
        // пропускаем 1й вызов, который запускается из-за useState
        if (didMount) {
            LStorageController.setPricesType(price);
            loadResults(1);
        }
    }, [price]);

    useEffect(() => {
        let productsIdsFromUrl: Array<string | number>;

        if (isRedirectedFromPrefs) {
            setPageTitle('Лучшие сочетания для&nbsp;твоей&nbsp;вещи');
        }
        const paramIds = queryParams.get('ids');
        if (paramIds) {
            productsIdsFromUrl = paramIds.split(',');
        }

        const _productId = getProductId();
        if (_productId) {
            setIsPending(true);
            GarderoboApi.getNextLook(
                _productId,
                1,
                LStorageController.getClothSizes(),
                LStorageController.getShoesSizes(),
                LStorageController.getPricesType(),
                LStorageController.getStyle(),
                LStorageController.getGender()
            )
                .then((data) => {
                    setLookIndexMax(data?.max_index);

                    // если зашли из кнопки "поделиться" - загружаем все товары по id, которые указаны в url-е
                    if (productsIdsFromUrl) {
                        productsIdsFromUrl.push(_productId);
                        const productsPromises = productsIdsFromUrl.map((productIdFromUrl) => GarderoboApi.getProductInfo(+productIdFromUrl).catch(() => null));
                        Promise.all(productsPromises)
                            .then((productsFromUrl) => {
                                const loadedProducts = productsFromUrl.filter((_product) => _product) as Array<IProductInfo>;
                                processLoadedProducts(loadedProducts, setProducts, productId, setMainProduct);
                            })
                            .finally(() => setIsPending(false));
                    } else {
                        processLoadedProducts(data.products, setProducts, productId, setMainProduct);
                    }
                })
                .catch((error: AppError) => {
                    setError(error.getErrorText());
                    setProducts([]);
                })
                .finally(() => setIsPending(false));
        } else {
            setProducts([]);
        }
    }, []);

    const linkBuyLook = (
        <button
            className={prefix + '__button-main-black ' + css[prefix + '__btn-buy']}
            type="button"
            onClick={() => {
                if (products?.length) {
                    Utils.openUrls(products.map((product) => product.url));
                }
            }}
        >
            Купить весь образ
        </button>
    );

    const ids = products?.map((product) => product.id).join(',');
    const fromParam = queryParams.get('from') ? `from=${queryParams.get('from')}` : '';
    const idsParam = ids ? `&ids=${ids}` : '';
    const url = `${process.env.REACT_APP_DOMAIN}${window.location.pathname}?${fromParam}${idsParam}`;
    // todo - do better with queryParams
    const btnShare = <ButtonShare url={encodeURIComponent(url)} title={pageTitle} />;

    const btnPrev = (
        <button
            className={prefix + '__button-main ' + css[prefix + '__controls-btn'] + ' ' + css[prefix + '__controls-btn--prev']}
            type="button"
            onClick={() => loadResults(-1)}
        >
            Предыдущий
        </button>
    );

    const btnNext = (
        <button
            className={prefix + '__button-main ' + css[prefix + '__controls-btn'] + ' ' + css[prefix + '__controls-btn--next']}
            type="button"
            onClick={() => loadResults(1)}
        >
            Следующий
        </button>
    );

    return (
        <section className={prefix + '__layout ' + css[prefix]}>
            <div className={prefix + '__layout-center ' + css[prefix + '__layout-center']}>
                {!products ? null : !products.length ? (
                    <div className={prefix + '__center-box'}>Лук не найден</div>
                ) : (
                    <>
                        <div className={prefix + '__mobile'}>
                            <h2 className={prefix + '__page-title-left'} dangerouslySetInnerHTML={{ __html: pageTitle }}></h2>
                            <div className={css[prefix + '__user-product']}>
                                {isRedirectedFromPrefs ? (
                                    <img className={css[prefix + '__main-block-card-image']} src={mainProductUrl} alt="Загруженное фото" />
                                ) : mainProduct ? (
                                    <ProductCard product={mainProduct} hasLike={true} urlPath={urlPath} />
                                ) : null}
                                <div className={css[prefix + '__user-product-options']}>
                                    <p className={css[prefix + '__user-product-text']}>
                                        Выбирай готовые образы или заменяй отдельные вещи в&nbsp;них, чтобы посмотреть больше вариантов
                                    </p>
                                    <div className={prefix + '__dropdown-wrapper ' + css[prefix + '__main-block-dropdown']}>
                                        <Dropdown
                                            options={pricesTypesObj}
                                            selectedItemCode={price}
                                            onSelect={(priceCode: PricesType) => {
                                                setPrice(priceCode);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul className={css[prefix + '__products']}>
                            {products.map((product) => (
                                <li key={product.id} className={css[prefix + '__product']}>
                                    <ProductCard
                                        product={product}
                                        hasRefresh={true}
                                        onRefresh={(_product, _setIsPending, direction) =>
                                            gotoNextProduct(
                                                _product?.id,
                                                _setIsPending,
                                                products,
                                                similarProducts,
                                                setSimilarProducts,
                                                setLookItemId,
                                                direction
                                            )
                                        }
                                        hasLike={true}
                                        urlPath={urlPath}
                                    />
                                </li>
                            ))}
                        </ul>

                        <div className={prefix + '__mobile ' + css[prefix + '__btns']}>
                            {linkBuyLook}
                            {btnShare}
                        </div>
                        <div className={prefix + '__mobile ' + css[prefix + '__sticky-controls']}>
                            {btnPrev}
                            {btnNext}
                        </div>
                    </>
                )}
            </div>
            <div className={prefix + '__layout-right ' + prefix + '__layout-right--with-nav ' + css[prefix + '__layout-right']}>
                <div>
                    <ButtonBack titleDesktop="Назад" cssClass={css[prefix + '__btn-back']} />
                    <div className={css[prefix + '__main-block']}>
                        <header className={css[prefix + '__main-block-header']}>
                            <h2 className={prefix + '__page-title-left'} dangerouslySetInnerHTML={{ __html: pageTitle }}></h2>
                            <h4 className={prefix + '__subtitle'}>
                                Выбирай готовые образы или заменяй отдельные вещи в них, чтобы посмотреть больше вариантов
                            </h4>
                        </header>
                        <div className={css[prefix + '__main-block-card-wrapper']}>
                            {isRedirectedFromPrefs ? (
                                <img className={css[prefix + '__main-block-card-image']} src={mainProductUrl} alt="Загруженное фото" />
                            ) : mainProduct ? (
                                <ProductCard product={mainProduct} hasLike={true} urlPath={urlPath} />
                            ) : null}
                            <div className={prefix + '__dropdown-wrapper ' + css[prefix + '__main-block-dropdown']}>
                                <Dropdown
                                    options={pricesTypesObj}
                                    selectedItemCode={price}
                                    onSelect={(priceCode: PricesType) => {
                                        setPrice(priceCode);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={css[prefix + '__controls']}>
                    <div className={css[prefix + '__controls-buy-share']}>
                        {linkBuyLook}
                        {btnShare}
                    </div>
                    <div className={css[prefix + '__controls-next-prev']}>
                        {btnPrev}
                        {btnNext}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default React.memo(ScreenBestCombos);
