import React from 'react';
import { IResponseImageProcessing } from '../models/models';

export interface IImageUploadContext {
    url: string;
    setUrl: (url: string) => void;
    image: string | null;
    setImage: (image: string | null) => void;
    clothCategories: Array<string>;
    setClothCategories: (categories: Array<string>) => void;
    clothCategoriesRus: Array<string>;
    setClothCategoriesRus: (categories: Array<string>) => void;
    error: Error | null;
    setError: (error: Error | null) => void;
    resultData?: IResponseImageProcessing | null;
    setResultData: (result: IResponseImageProcessing | null) => void;
}

interface ISimilarContext extends IImageUploadContext {}

interface ITotalLookContext extends IImageUploadContext {}

export const SimilarContext = React.createContext<ISimilarContext>({
    url: '',
    setUrl: (url: string) => {},
    image: null,
    setImage: (image: string | null) => {},
    clothCategories: [],
    setClothCategories: (categories: Array<string>) => {},
    clothCategoriesRus: [],
    setClothCategoriesRus: (categories: Array<string>) => {},
    resultData: null,
    setResultData: () => {},
    error: null,
    setError: () => {},
});

export const TotalLookContext = React.createContext<ITotalLookContext>({
    url: '',
    setUrl: (url: string) => {},
    image: null,
    setImage: (image: string | null) => {},
    clothCategories: [],
    setClothCategories: (categories: Array<string>) => {},
    clothCategoriesRus: [],
    setClothCategoriesRus: (categories: Array<string>) => {},
    resultData: null,
    setResultData: () => {},
    error: null,
    setError: () => {},
});

export interface IErrorExtended {
    text: string;
    noOkButton?: boolean;
    okBtnText?: string;
}

export interface ILikedProductItem {
    productId: number;
    isLiked: boolean;
}
export interface IGlobalContext {
    error: string | IErrorExtended;
    setError: (error: string | IErrorExtended) => void;
    isPending: boolean;
    setIsPending: (value: boolean) => void;
    likedProductId: ILikedProductItem | null;
    setLikedProductId: (value: ILikedProductItem | null) => void;
}

export const GlobalContext = React.createContext<IGlobalContext>({
    error: '',
    setError: () => {},
    isPending: false,
    setIsPending: () => {},
    likedProductId: null,
    setLikedProductId: () => {},
});
